<template>
    <div>
        <div>
            <div class="mt-3 sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-xl font-semibold text-gray-900">
                        Packages
                    </h1>
                </div>
                <div
                    class="flex mt-4 mr-4 rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                >
                    <router-link
                        :to="{ name: 'admin_addpackage' }"
                        class="text-center"
                    >
                        Add Packages
                    </router-link>
                </div>
            </div>
            <div class="mt-6 flex flex-col">
                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div
                        class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
                    >
                        <div
                            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                        >
                            <!-- Table Headings -->
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead class="bg-gray-50">
                                    <tr class="divide-x divide-gray-200">
                                        <th
                                            class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                            scope="col"
                                        >
                                            Package's name
                                        </th>
                                        <th
                                            class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            Description
                                        </th>
                                        <th
                                            class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            Price (RM)
                                        </th>
                                        <th
                                            class="text-center px-4 py-3.5 text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            Update section
                                        </th>
                                    </tr>
                                </thead>
                                <!-- Package -->
                                <tbody
                                    class="divide-y divide-gray-200 bg-white"
                                >
                                    <tr
                                        v-for="(pack, key) in admin.getPackages
                                            .data"
                                        :key="key"
                                        class="divide-x divide-gray-200"
                                    >
                                        <td
                                            class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6"
                                        >
                                            {{ pack.name }}
                                        </td>
                                        <td
                                            class="whitespace-nowrap p-4 text-sm text-gray-500"
                                        >
                                            {{ pack.desc }}
                                        </td>
                                        <td
                                            class="whitespace-nowrap p-4 text-sm text-gray-500"
                                        >
                                            {{ pack.price }}
                                        </td>
                                        <td
                                            class="py-2 flex justify-center gap-4"
                                        >
                                            <button
                                                class="rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-auto"
                                                type="button"
                                            >
                                                <router-link
                                                    :to="{
                                                        name: 'admin_editpackage',
                                                        params: { id: pack.id },
                                                    }"
                                                    class="text-center"
                                                >
                                                    Edit Package
                                                </router-link>
                                            </button>

                                            <button
                                                @click.prevent="
                                                    admin.deletePackage(pack)
                                                "
                                                class="rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-auto"
                                                type="button"
                                            >
                                                Delete Package
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { RouterLink } from "vue-router";
import { useAdminStore } from "@/store/admin.js";

const admin = useAdminStore();
admin.loadPackage();
</script>
