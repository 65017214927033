<template>
    <div class="container mx-auto px-5">
        <div class="border rounded-lg p-5 bg-white">
            <div class="flex justify-between">
                <div
                    class="flex justify-center md:justify-start font-bold items-center"
                >
                    Black List
                </div>
            </div>
        </div>
        <div
            class="border relative mt-1 rounded-md shadow-sm px-3 py-2 bg-gray-100"
        >
            <label
                class="block text-xs font-medium text-gray-400 mb-1"
                for="blacklist"
            >
                Insert Blacklist Here
            </label>
            <div class="flex">
                <input
                    id="blacklist"
                    class="block w-full sm:text-sm outline-none px-2 py-1 rounded-tl rounded-bl"
                    name="blacklist"
                    type="text"
                    v-model="form.value"
                    @keyup="filerBlacklist"
                    @keyup.enter="addBlacklist"
                    placeholder="Insert Blacklist Here"
                />
                <button
                    @click="addBlacklist"
                    class="px-3 py-2 bg-green-500 hover:bg-green-600 text-white rounded-tr rounded-br"
                >
                    Add
                </button>
            </div>
        </div>
        <div class="border rounded-lg mt-5 pb-5 bg-white">
            <main class="mx-auto w-full md:w-1/2 my-10">
                <div
                    class="mx-auto w-full text-center md:w-1/2 flex flex-col gap-3 mb-5"
                >
                    <div class="">
                        <label class="text-xl font-bold">Blacklist</label>
                    </div>
                    <template
                        v-if="
                            admin.getBlacklists &&
                            admin.getBlacklists.length > 0
                        "
                    >
                        <div
                            v-for="(blacklist, key) in admin.getBlacklists"
                            :key="key"
                            class="flex justify-between"
                        >
                            <div>{{ blacklist.value }}</div>
                            <div>
                                <button
                                    @click="deleteBlacklist(blacklist.id)"
                                    class="rounded bg-red-500 hover:bg-red-600 text-white px-3 py-2"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </template>
                    <div v-else>
                        <div class="text-center text-orange-500 font-bold">
                            No Blacklist Currently
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>
<script setup>
import { useAdminStore } from "@/store";
import { reactive } from "vue";

const admin = useAdminStore();
const form = reactive({
    value: null,
});
const filerBlacklist = () => {
    admin.loadBlacklist({ value: form.value });
};
const addBlacklist = () => {
    admin.addBlacklist({ value: form.value });
};
const deleteBlacklist = (id) => {
    admin.deleteBlacklist(id);
};
admin.loadBlacklist();
</script>
