<template>
    <div>
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <h3 class="mt-2 text-lg font-medium leading-6 text-gray-700">
                    Users
                </h3>
            </div>
            <div
                class="flex mr-6 mt-4 rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
                <router-link
                    :to="{ name: 'admin_adduser' }"
                    class="text-center"
                >
                    Add User
                </router-link>
            </div>
        </div>
        <div class="mt-8 flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                    class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
                >
                    <div
                        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                    >
                        <!-- Table Content  -->
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-100">
                                <tr>
                                    <th
                                        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        scope="col"
                                    >
                                        Username
                                    </th>
                                    <th
                                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        scope="col"
                                    >
                                        Email
                                    </th>
                                    <th
                                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        scope="col"
                                    >
                                        Password
                                    </th>

                                    <th
                                        class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                                        scope="col"
                                    >
                                        Update section
                                    </th>
                                </tr>
                            </thead>
                            <!-- Users -->
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr
                                    v-for="(user, key) in admin.getUsers.data"
                                    :key="key"
                                    class="divide-x divide-gray-200"
                                >
                                    <td
                                        class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6"
                                    >
                                        <div class="flex items-center">
                                            <div class="ml-4">
                                                <div
                                                    class="font-medium text-gray-900"
                                                >
                                                    {{ user.username }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                    >
                                        <div class="text-gray-900">
                                            {{ user.email }}
                                        </div>
                                    </td>
                                    <td
                                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                    >
                                        <div class="text-gray-900">
                                            {{ user.password }}
                                        </div>
                                    </td>

                                    <td class="py-2 flex justify-center gap-4">
                                        <button
                                            class="rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-auto"
                                            type="button"
                                        >
                                            <router-link
                                                :to="{
                                                    name: 'admin_edituser',
                                                    params: { id: user.id },
                                                }"
                                                class="text-center"
                                            >
                                                Edit User
                                            </router-link>
                                        </button>

                                        <button
                                            @click.prevent="
                                                admin.deleteUser(user)
                                            "
                                            class="rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-auto"
                                            type="button"
                                        >
                                            Delete User
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Pagination
                            :data="admin.getUsers"
                            @change="changePage"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { RouterLink } from "vue-router";
import { useAdminStore } from "@/store/admin.js";

const form = {
    page: 1,
};

const admin = useAdminStore();
admin.loadUser(form);
const changePage = (page) => {
    form.page = page;
    admin.loadUser(form);
};
</script>
