<template>
    <div v-if="control.data" class="relative">
        <div
            @click.prevent="close"
            class="fixed w-screen top-0 h-screen z-30 bg-gray-800 bg-opacity-75"
            :class="{ 'z-50': control.type === 'captcha' }"
        ></div>
        <div
            class="mx-auto md:w-1/2 z-40 w-full h-screen md:max-h-[500px] md:h-auto md:p-10 top-0 fixed inset-x-0 rounded md:top-10"
            :style="{ 'background-color: #306D85': control.type === 'captcha' }"
            :class="{ 'bg-white': control.type !== 'captcha' }"
        >
            <a
                v-if="control.type !== 'captcha'"
                href="#"
                class="absolute right-0 top-0 md:-right-5 md:-top-5 rounded-full text-white p-2 bg-red-600"
                @click.prevent="close"
            >
                <svg
                    class="w-5"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                >
                    <path
                        d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
                    />
                </svg>
            </a>
            <div class="px-6 md:px-0 my-4 text-lg flex items-center w-full">
                <iframe
                    v-if="control.type === 'fpay'"
                    ref="iframe"
                    :src="control.data"
                    width="100%"
                    height="500"
                    frameborder="0"
                ></iframe>
                <div
                    v-else-if="control.type === 'crypto'"
                    class="mx-auto bg-white w-full"
                >
                    <div class="px-4 py-5 sm:p-6">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">
                            Please Enter Transaction Code To Confirm Payment.
                        </h3>
                        <div class="mt-2 text-sm text-gray-500 w-full">
                            <label for="address" class="block font-semibold"
                                >Our Payment Address</label
                            >
                            <input
                                type="text"
                                :value="control.data"
                                id="address"
                                readonly
                                disabled
                                class="block w-full sm:text-sm rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-500 focus-within:ring-blue-500"
                                placeholder="Payment Address"
                            />
                        </div>
                        <div class="mt-2 text-sm text-gray-500 w-full">
                            <label for="amount" class="block font-semibold"
                                >USDT Amount</label
                            >
                            <input
                                type="text"
                                :value="control.amount"
                                id="amount"
                                readonly
                                disabled
                                class="block w-full sm:text-sm rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-500 focus-within:ring-blue-500"
                                placeholder="Amount"
                            />
                        </div>
                        <div class="mt-2 text-sm text-gray-500 w-full">
                            <label for="transaction" class="block font-semibold"
                                >Enter Transaction Code</label
                            >
                            <input
                                type="text"
                                v-model="control.transaction"
                                id="transaction"
                                class="block w-full sm:text-sm rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-500 focus-within:ring-blue-500"
                                placeholder="Transaction Code"
                            />
                        </div>
                        <div class="mt-2 text-center">
                            <button
                                @click.prevent="confirmPayment"
                                type="button"
                                class="justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    v-else-if="control.type === 'auth'"
                    class="mx-auto bg-white w-full h-auto"
                >
                    <div class="text-center">
                        <img
                            class="w-40 mx-auto"
                            :src="require('@/assets/logo.png')"
                        />
                    </div>
                    <div>
                        <!-- Mobile View -->
                        <div class="sm:block">
                            <nav
                                aria-label="Tabs"
                                class="border isolate flex divide-x divide-gray-200 rounded-lg shadow-lg"
                            >
                                <a
                                    v-for="(tab, tabIdx) in tabs"
                                    :key="tab.name"
                                    :aria-current="
                                        tab.code ? 'page' : undefined
                                    "
                                    :class="[
                                        form.type === tab.code
                                            ? 'text-gray-900'
                                            : 'text-gray-500 hover:text-gray-700',
                                        tabIdx === 0 ? 'rounded-l-lg' : '',
                                        tabIdx === tabs.length - 1
                                            ? 'rounded-r-lg'
                                            : '',
                                        'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10',
                                    ]"
                                    href="#"
                                    @click="form.type = tab.code"
                                >
                                    <span>{{ tab.name }}</span>
                                    <span
                                        :class="[
                                            form.type === tab.code
                                                ? 'bg-indigo-500'
                                                : 'bg-transparent',
                                            'absolute inset-x-0 bottom-0 h-0.5',
                                        ]"
                                        aria-hidden="true"
                                    />
                                </a>
                            </nav>
                        </div>
                    </div>
                    <div
                        class="mx-auto w-full md:w-1/2 flex flex-col gap-3 my-5"
                    >
                        <input
                            id="username"
                            v-model="form.username"
                            class="block w-full sm:text-sm rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-500 focus-within:ring-blue-500"
                            name="name"
                            placeholder="Username"
                            type="text"
                        />
                        <input
                            v-if="form.type === 'register'"
                            id="email"
                            v-model="form.email"
                            class="block w-full sm:text-sm rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-500 focus-within:ring-blue-500"
                            name="email"
                            placeholder="Email"
                            type="email"
                        />
                        <input
                            id="password"
                            v-model="form.password"
                            class="block w-full sm:text-sm rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-500 focus-within:ring-blue-500"
                            name="password"
                            placeholder="Password"
                            type="password"
                        />
                        <input
                            v-if="form.type === 'register'"
                            id="confirm_password"
                            v-model="form.password_confirmation"
                            class="block w-full sm:text-sm rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-500 focus-within:ring-blue-500"
                            name="confirm_password"
                            placeholder="Confirm Password"
                            type="password"
                        />
                        <button
                            v-if="form.type === 'register'"
                            class="w-full rounded-md border border-blue-500 bg-white px-4 py-2 text-sm font-medium text-blue-500 hover:bg-blue-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            type="button"
                            @click="registerAction"
                        >
                            REGISTER
                        </button>
                        <button
                            v-if="form.type === 'login'"
                            class="w-full rounded-md border border-blue-500 bg-white px-4 py-2 text-sm font-medium text-blue-500 hover:bg-blue-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            type="button"
                            @click="loginAction"
                        >
                            LOGIN
                        </button>
                    </div>
                </div>
                <!--v-else-if="control.type === 'captcha'"-->
                <div
                    class="text-center mx-auto absolute top-10 md:top-20 h-auto w-full z-[60]"
                >
                    <div class="text-center font-bold text-lg text-white">
                        Checking Browser...
                    </div>
                    <NotRobot />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { reactive, ref } from "vue";
import { useAuthStore, useMainStore } from "@/store/index.js";
import { useReCaptcha } from "vue-recaptcha-v3";
import NotRobot from "@/components/NotRobot.vue";

// eslint-disable-next-line no-undef
const props = defineProps({
    modal: {
        type: Object,
        required: true,
    },
    id: {
        type: Number,
        required: true,
    },
});
const control = reactive(props.modal);

const { recaptchaLoaded, executeRecaptcha } = useReCaptcha();

const main = useMainStore();
const iframe = ref(null);

const tabs = [
    { name: "Login", code: "login" },
    { name: "Register", code: "register" },
];

let form = reactive({
    type: "login",
    token: null,
    email: null,
    username: null,
    password: null,
    password_confirmation: null,
});

const confirmPayment = () => {
    if (control.type === "crypto") {
        const main = useMainStore();
        main.confirmTrx(control.data, {
            transaction: control.transaction,
            type: control.type,
        }).then((res) => {
            if (res.success) {
                close();
            }
        });
    }
};

const loginAction = async () => {
    if (!main.isLoading) {
        main.setLoading(true);
        await recaptchaLoaded();

        // Execute reCAPTCHA with action "login".
        const token = await executeRecaptcha("login");
        form.rc = "login";
        form.token = token;

        const auth = useAuthStore();
        await auth.login(form);
        close();
    }
};
const registerAction = async () => {
    if (!main.isLoading) {
        main.setLoading(true);
        if (form.password === form.password_confirmation) {
            await recaptchaLoaded();

            // Execute reCAPTCHA with action "login".
            const token = await executeRecaptcha("register");
            form.rc = "register";
            form.token = token;

            const auth = useAuthStore();
            await auth.register(form);
            close();
        } else {
            main.addMessage({
                message: "Password not match!",
                success: false,
                errors: [],
            });
        }
    }
};

const close = () => {
    main.closeModal(props.id);
};
</script>
