<template>
    <div class="sea">
        <div class="bubble"></div>
        <div class="submarine-wrapper">
            <div class="submarine-body">
                <div class="window"></div>
                <div class="engine"></div>
                <div class="light"></div>
            </div>
            <div class="helix"></div>
            <div class="hat">
                <div class="leds-wrapper">
                    <div class="periscope"></div>
                    <div class="leds"></div>
                </div>
            </div>
        </div>
    </div>
    <vue-recaptcha
        v-show="showRecaptcha"
        class="mx-auto relative w-[400px]"
        :sitekey="siteKey"
        size="normal"
        theme="light"
        hl="tr"
        :loading-timeout="loadingTimeout"
        @verify="recaptchaVerified"
        @expire="recaptchaExpired"
        @fail="recaptchaFailed"
        @error="recaptchaError"
        ref="recaptcha"
    >
    </vue-recaptcha>
</template>
<script setup>
import vueRecaptcha from "vue3-recaptcha2";
import { useMainStore } from "@/store/index.js";
import { ref } from "vue";

const main = useMainStore();
const siteKey = process.env.VUE_APP_RECAPCTAV2_KEY;
const showRecaptcha = main.showRecaptcha;
const loadingTimeout = 0;
const recaptcha = ref(null);
const recaptchaVerified = (e) => {
    main.closeModal("captcha");
    main.startBotAction(e);
};
const recaptchaExpired = () => {
    // err
    // console.log(err);
    recaptcha.value.reset();
};
const recaptchaFailed = () => {
    main.closeModal("captcha");
    // err
    // console.log(err);
    // this.$refs.vueRecaptcha.reset();
    // main.addMessage({ success: false, message: err });
};
const recaptchaError = () => {
    main.closeModal("captcha");
    // reason
    // console.log(reason);
    // main.addMessage({ success: false, message: reason });
};
</script>
<style lang="scss">
@import "@/assets/animate.scss";
</style>
