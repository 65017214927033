import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import axios from "@/axios.js";
import router from "@/router";
import { useMainStore } from "@/store/main.js";

export const useAuthStore = defineStore({
    id: "auth",
    state: () => ({
        token: useStorage("token", null),
        user: useStorage("user", {}),
    }),
    getters: {
        getUser: (state) => state.user,
        getToken: (state) => state.token,
    },
    actions: {
        resetAuth() {
            this.token = null;
            this.user = {};
        },
        async login(form) {
            await axios.post("/login", form).then(({ data }) => {
                this.token = data.token;
                this.user = data.user;
                router
                    .isReady()
                    .then(() => router.replace({ name: "dashboard" }));
                location.reload();
            });
        },

        async register(form) {
            await axios.post("/register", form).then(({ data }) => {
                this.token = data.token;
                this.user = data.user;
                router
                    .isReady()
                    .then(() => router.replace({ name: "dashboard" }));
                location.reload();
            });
        },
        async updatePassword(form) {
            await axios.post("/password/update", form).then(({ data }) => {
                this.user = data;
                router
                    .isReady()
                    .then(() => router.replace({ name: "dashboard" }));
            });
        },
        async applyAPI(form) {
            await axios.post("/api/apply", form).then(async () => {
                await this.me();
            });
        },
        async me() {
            return await axios.get("/me").then(({ data, success }) => {
                if (success) {
                    this.user = data;
                } else {
                    const main = useMainStore();
                    main.addMessage({
                        message: "Expired, Please relogin.",
                        status: false,
                    });
                }
            });
        },
        logout() {
            return axios
                .post("/logout")
                .then(() => {
                    this.token = null;
                    this.user = {};
                })
                .finally(() => {
                    this.token = null;
                    this.user = {};
                    router.push({ name: "login" });
                });
        },
    },
});
