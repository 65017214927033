export default {
    env(key, defaults = null) {
        const value = import.meta.env[key];
        return value || defaults;
    },
    getDataId(result) {
        const id = parseInt(result.id.slice(result.id.indexOf("-") + 1));
        return id + 1;
    },
    getDetailTemplate(type) {
        switch (type) {
            case "MYidentiy":
                return "MYidentiy";
            default:
                return "Detail";
        }
    },
};
