<template>
    <div>
        <!-- List of table -->
        <div>
            <div class="sm:flex sm:items-center mt-4">
                <div class="sm:flex-auto">
                    <h1 class="text-xl font-semibold text-gray-900">
                        Report list
                    </h1>
                </div>
                <div
                    class="bg-indigo-800 hover:bg-indigo-900 text-white font-bold py-2 px-4 rounded"
                >
                    <router-link
                        :to="{ name: 'admin_report' }"
                        class="text-center"
                    >
                        Back To Report
                    </router-link>
                </div>
            </div>
            <!-- Content  -->
            <div class="mt-6 border border-gray-300 rounded p-5 bg-white">
                <h1 class="font-bold text-gray-700">Filter Result</h1>
                <div class="flex flex-row flex-wrap mt-5">
                    <div class="w-full md:w-1/4 px-3 py-2 md:py-0">
                        <label
                            class="block text-sm font-medium text-gray-700"
                            for="date"
                            >From Date</label
                        >
                        <input
                            id="date"
                            class="mt-1 block w-full border rounded-md border-gray-300 py-2 pl-3 pr-3 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="date"
                            type="date"
                        />
                    </div>
                    <div class="w-full md:w-1/4 px-3 py-2 md:py-0">
                        <label
                            class="block text-sm font-medium text-gray-700"
                            for="date"
                            >To Date</label
                        >
                        <input
                            id="date"
                            class="mt-1 block w-full border rounded-md border-gray-300 py-2 pl-3 pr-3 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="date"
                            type="date"
                        />
                    </div>
                    <div class="w-full md:w-1/4 px-3 py-2 md:py-0">
                        <label
                            class="block text-sm font-medium text-gray-700"
                            for="trans_type"
                            >Packages</label
                        >
                        <select
                            id="trans_type"
                            class="mt-1 block w-full border rounded-md border-gray-300 py-2 pl-3 pr-3 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="product_type"
                        >
                            <option selected>All</option>
                            <option selected>Basic</option>
                            <option selected>Gold</option>
                            <option selected>Premium</option>
                        </select>
                    </div>
                    <div class="w-full md:w-1/4 px-3 py-2 md:py-0">
                        <label
                            class="block text-sm font-medium text-gray-700"
                            for="trans_status"
                            >Status</label
                        >
                        <select
                            id="trans_status"
                            class="mt-1 block w-full border rounded-md border-gray-300 py-2 pl-3 pr-3 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="trans_status"
                        >
                            <option selected>All</option>
                            <option selected>Active</option>
                            <option selected>Deactive</option>
                        </select>
                    </div>
                </div>
                <!-- Filter buttons -->
                <div class="flex flex-row flex-wrap mt-5 gap-2">
                    <a
                        class="border rounded px-5 py-2 font-semibold text-white bg-indigo-600"
                        href="#"
                        >Filter</a
                    >

                    <a
                        class="border rounded px-6 py-2 font-semibold text-gray-700 bg-orange-400"
                        href="#"
                        >Reset</a
                    >
                    <a
                        class="border rounded px-6 py-2 font-semibold text-gray-700 bg-yellow-500"
                        href="#"
                        >Generate</a
                    >
                </div>
            </div>
            <div class="sm:flex sm:items-center mt-4">
                <div class="sm:flex-auto">
                    <h1 class="text-xl font-semibold text-gray-900">Report</h1>
                </div>
                <div
                    class="bg-indigo-800 hover:bg-indigo-900 text-white font-bold py-2 px-4 rounded"
                ></div>
            </div>
            <div class="mt-8 flex flex-col">
                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div
                        class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
                    >
                        <div
                            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                        >
                            <table
                                class="border border-gray-200 min-w-full divide-y divide-gray-300"
                            >
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th
                                            class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                            scope="col"
                                        >
                                            Order Reference
                                        </th>
                                        <th
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            Created on
                                        </th>
                                        <th
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            Username
                                        </th>
                                        <th
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            Email
                                        </th>
                                        <th
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            Total USDT
                                        </th>
                                        <th
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            Total TRX
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    v-for="(report, key) in admin.getReports
                                        .data"
                                    :key="key"
                                    class="divide-y divide-gray-200 bg-white"
                                >
                                    <tr>
                                        <td
                                            class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6"
                                        >
                                            {{ report.reference }}
                                        </td>
                                        <td
                                            class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900"
                                        >
                                            {{ report.created_at }}
                                        </td>
                                        <td
                                            class="whitespace-nowrap px-2 py-2 text-sm text-gray-900"
                                        >
                                            {{ report.username }}
                                        </td>
                                        <td
                                            class="whitespace-nowrap px-2 py-2 text-sm text-gray-500"
                                        >
                                            {{ report.email }}
                                        </td>
                                        <td
                                            class="whitespace-nowrap px-2 py-2 text-sm text-gray-500"
                                        >
                                            {{ admin.getUsers.ttl_usdt }}
                                        </td>
                                        <td
                                            class="whitespace-nowrap px-2 py-2 text-sm text-gray-500"
                                        >
                                            {{ admin.getUsers.ttl_trx }}
                                        </td>
                                    </tr>
                                    <!-- More transactions... -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
            >
                <div class="flex flex-1 justify-between sm:hidden">
                    <a
                        href="#"
                        class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        >Previous</a
                    >
                    <a
                        href="#"
                        class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        >Next</a
                    >
                </div>
                <div
                    class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between"
                >
                    <div>
                        <p class="text-sm text-gray-700">
                            Showing
                            <span class="font-medium">1</span>
                            to
                            <span class="font-medium">10</span>
                            of
                            <span class="font-medium">20</span>
                            results
                        </p>
                    </div>
                    <div>
                        <nav
                            class="isolate inline-flex -space-x-px rounded-md shadow-sm"
                            aria-label="Pagination"
                        >
                            <a
                                href="#"
                                class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                            >
                                <span class="sr-only">Previous</span>
                                <svg
                                    class="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </a>
                            <a
                                href="#"
                                aria-current="page"
                                class="relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
                                >1</a
                            >
                            <a
                                href="#"
                                class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                >2</a
                            >
                            <a
                                href="#"
                                class="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
                                >3</a
                            >
                            <span
                                class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
                                >...</span
                            >
                            <a
                                href="#"
                                class="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex"
                                >8</a
                            >
                            <a
                                href="#"
                                class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                >9</a
                            >
                            <a
                                href="#"
                                class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                >10</a
                            >
                            <a
                                href="#"
                                class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                            >
                                <span class="sr-only">Next</span>

                                <svg
                                    class="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </a>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { RouterLink } from "vue-router";
import { useAdminStore } from "@/store/admin.js";

const admin = useAdminStore();
admin.loadUser();
admin.loadReport();
</script>
