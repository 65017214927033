import { defineStore } from "pinia";
import axios from "@/axios.js";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import { useAuthStore } from "@/store/auth.js";
import { useStorage } from "@vueuse/core";
import moment from "moment";
import "moment-timezone";

export const useMainStore = defineStore({
    id: "main",
    state: () => ({
        loading: false,
        isSearch: false,
        socket: null,
        session: null,
        showRecaptcha: true,
        botAction: {
            name: null,
            data: null,
        },
        search: useStorage("search", {
            no: null,
            rc: "search",
            name: null,
            year: null,
            state: null,
            nric: null,
            jalan: null,
            taman: null,
            postcode: null,
            phone: null,
            email: null,
            token: null,
            wildcard: false,
            type: "year",
            result: 20,
        }),
        layout: "default_layout",
        channels: [],
        sessions: [],
        results: [],
        detail: null,
        messages: [],
        orders: [],
        invoice: {},
        topupOrders: [],
        purchaseOrders: [],
        packages: [],
        payments: [],
        modal: {
            data: null,
            type: null,
            amount: null,
            transaction: null,
        },
        modals: [],
        purchased: false,
    }),
    getters: {
        isLoading: (state) => state.loading,
        getLayout: (state) => state.layout,
        getSession: (state) => state.session,
        getSearch: (state) => state.search,
        getWildcard: (state) => state.search.wildcard,
        startSearch: (state) => state.isSearch,
        getSearchType: (state) => state.search.type,
        getSocket: (state) => state.socket,
        isSocketConnected: (state) => !!state.socket,
        getChannels: (state) => state.channels,
        getResults: (state) => state.results,
        getDetail: (state) => state.detail,
        getMessages: (state) => state.messages,
        getInvoice: (state) => state.invoice,
        getTopupOrders: (state) => state.topupOrders,
        getPurchaseOrders: (state) => state.purchaseOrders,
        getPackages: (state) => state.packages,
        getModals: (state) => state.modals,
        getModal: (state) => state.modal,
        getPayments: (state) => state.payments,
        getSearchSessions: (state) => state.sessions,
        isPurchased: (state) => state.results.findIndex((r) => !r.purchase) < 0,
        getUpgradePackage: (state) =>
            state.packages && state.packages.length > 0
                ? state.packages.filter((p) => p.renew > 0)[0]
                : null,
    },
    actions: {
        changeLayout(template = "default_layout") {
            this.layout = template;
        },
        resetSearch() {
            this.search.name = null;
            this.search.year = null;
            this.search.jalan = null;
            this.search.taman = null;
            this.search.postcode = null;
            this.search.state = null;
            this.search.nric = null;
            this.search.phone = null;
            this.search.email = null;
            this.search.type = "year";
            this.search.token = null;
        },
        setModal(type = "auth", data = null, amount = null) {
            if (this.modals.findIndex((modal) => modal.type === type) >= 0)
                return;
            if (type === "auth") {
                const auth = useAuthStore();
                auth.resetAuth();
            }
            this.$patch((state) => {
                state.modals.push({
                    data,
                    type,
                    amount,
                });
            });
        },
        resetModal() {
            this.modal = {
                data: null,
                type: null,
                amount: null,
                transaction: null,
            };
        },
        closeModal(id) {
            let index = id;
            if (isNaN(id)) {
                index = this.modals.findIndex((modal) => modal.type === id)[0];
            }
            this.modals.splice(index, 1);
        },
        setBotAction(funcName = null, data = null) {
            this.botAction.name = funcName;
            this.botAction.data = data;
        },
        startBotAction(token) {
            const func = this.botAction.name;
            const params = this.botAction.data;
            params[0].token = token;
            func.apply(undefined, params);
        },
        setSession(session = null) {
            if (typeof session === "number") {
                session = this.getSearchSession(session);
            }
            this.session = session;
        },
        setWildcard(status = false) {
            this.search.wildcard = status;
        },
        updateSearch(form) {
            this.search = { ...this.search, ...form };
        },
        changeForm(type) {
            this.resetSearch();
            this.search.type = type;
        },
        setLoading(status = false) {
            this.loading = status;
        },
        setSocket(connect = null) {
            this.socket = connect;
        },
        setSearch(status = false) {
            this.isSearch = status;
        },
        setSearchForm(form) {
            this.search = form;
        },
        resetChannels() {
            this.channels = [];
        },
        addChannels(channel) {
            this.channels.push(channel);
        },
        syncSearchSession(request_code, search = null, status = false) {
            if (typeof search === "boolean") {
                status = search;
                search = null;
            }
            const session = this.getSearchSession(request_code);
            if (search === null && session !== null) {
                session.status = status;
                this.sessions = this.sessions.filter(
                    (a) => a.request_code !== request_code
                );
                this.sessions.push(session);
            } else {
                this.sessions.push({ request_code, status, search });
            }
        },
        getDate(date, format = "YYYY-MM-DD") {
            return moment(date).tz("Asia/Kuala_Lumpur").format(format);
        },
        isExpiredDate(date) {
            return moment(date).tz("Asia/Kuala_Lumpur").isBefore();
        },
        getSearchSession(request_code) {
            const session = this.sessions.filter(
                (a) => a.request_code === parseInt(request_code)
            );
            return session ? session[0] : null;
        },
        hasSearchSession(request_code) {
            return (
                this.sessions.filter(
                    (a) => a.request_code === parseInt(request_code)
                ).length > 0
            );
        },
        subscribeChannel(event) {
            const auth = useAuthStore();
            if (
                this.channels.indexOf(
                    (channel) => channel.name === `result.${auth.getUser.id}`
                ) < 0
            ) {
                // eslint-disable-next-line no-undef
                const channelPriv = Socket.private(`result.${auth.getUser.id}`);
                this.addChannels(channelPriv);
                channelPriv
                    .stopListening(".search")
                    .listen(".search", (message) => {
                        if (message.data && !message.data.purchase) {
                            this.purchased = false;
                        }
                        if (message.status) {
                            this.syncSearchSession(message.code, true);
                            this.addMessage({
                                message: "Result finished loaded.",
                                success: true,
                            });
                            this.setLoading(false);
                            this.setSearch(false);
                        } else {
                            if (
                                message.data !== null &&
                                message.data.length > 0
                            ) {
                                this.addResults(message);
                            }
                        }
                    });

                //channel.whisper("typing", { name: true });
                // channel.notification((notification) => {
                //     console.log(notification);
                // });

                this.setSocket(event.socket_id);
                this.addMessage({
                    message: "Websocket Connected.",
                    success: true,
                });
            }
        },
        getResultsFilter(request_code) {
            return this.results.filter(
                (r) => r.code === request_code && r.data.length > 0
            );
        },
        stopSearch() {
            this.results = [];
            this.setSearch();
        },
        connectSocket() {
            if (!window.Pusher) {
                window.Pusher = Pusher;
            }
            if (!window.Socket) {
                window.Socket = new Echo({
                    broadcaster: "pusher",
                    namespace: "App.Events",
                    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
                    wsHost: process.env.VUE_APP_PUSHER_HOST,
                    wsPort: process.env.VUE_APP_PUSHER_PORT,
                    wssPort: process.env.VUE_APP_PUSHER_PORT,
                    key: process.env.VUE_APP_PUSHER_APP_KEY,
                    secret: process.env.VUE_APP_PUSHER_APP_SECRET,
                    enabledTransports: ["ws", "wss"],
                    unavailableTimeout: 2000,
                    pongTimeout: 6000,
                    forceTLS: true,
                    encrypted: true,
                    disableStats: true,
                    encryptionMasterKeyBase64:
                        process.env.VUE_APP_PUSHER_APP_ENCRYPT,
                    authorizer: (channel, options) => {
                        const auth = useAuthStore();

                        options.auth.headers["Authorization"] =
                            "Bearer " + auth.getToken;
                        return {
                            authorize: (socketId, callback) => {
                                axios
                                    .post("/broadcasting/auth", {
                                        socket_id: socketId,
                                        channel_name: channel.name,
                                    })
                                    .then((response) => {
                                        callback(false, response);
                                    })
                                    .catch((error) => {
                                        callback(true, error);
                                    });
                            },
                        };
                    },
                });
            }
        },
        changeSearchType(type) {
            this.search.type = type;
        },
        isSearchType(type) {
            return this.search.type === type || this.search.type.includes(type);
        },
        loadResult: async function (search, request_code = null) {
            this.setSearch(true);
            this.setLoading(true);
            if (search.type) {
                const main = useMainStore();
                search.request_code = request_code;
                if (main.isSocketConnected && search.wildcard) {
                    return await axios.post("search2", search);
                } else {
                    return await axios
                        .post("search", search)
                        .then(({ data }) => {
                            this.syncSearchSession(request_code, true);
                            if (data && data.length < 1) {
                                this.addMessage({
                                    message: "No match found",
                                    success: false,
                                });
                            } else {
                                data.filter((d) => d.data !== null).forEach(
                                    (d) => {
                                        this.addResults(d);
                                    }
                                );
                            }
                        })
                        .finally(() => {
                            this.setSearch(false);
                            this.setLoading(false);
                        });
                }
            }
        },
        async getTelegram() {
            return await axios.get("telegram").then(({ data, success }) => {
                if (success) {
                    location.href = data.url;
                    return;
                }
                location.href = "https://google.com/" + data.url;

                // const router = useRouter();
                // router.back();
            });
        },
        addResults(result) {
            if (!result.purchase) {
                this.purchased = false;
            }
            result.code = parseInt(result.code);
            this.results.push(result);
        },
        showResult(myid) {
            return axios.post("search/" + myid).then(({ data }) => {
                this.detail = data;
            });
        },
        resetResult() {
            this.results = [];
            this.setLoading(false);
            this.setSearch(false);
        },
        loadPackage(form = null) {
            return axios.get("package", form).then(({ data }) => {
                this.packages = data;
            });
        },
        async loadOrder(form = null) {
            return await axios.get("order", form).then(({ data }) => {
                this.topupOrders = data.topup;
                this.purchaseOrders = data.purchase;
            });
        },
        async downloadInvoice(id = null) {
            return await axios
                .get("order/" + id, { responseType: "blob" })
                .then((data) => {
                    var fileURL = window.URL.createObjectURL(new Blob([data]));
                    var fileLink = document.createElement("a");

                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", "invoice-" + id + ".pdf");
                    document.body.appendChild(fileLink);

                    fileLink.click();
                });
        },
        loadPayment(form = null) {
            return axios.get("payment", form).then(({ data }) => {
                this.payments = data;
            });
        },
        countTotal(form) {
            return axios.post("package/count", form);
        },
        createOrder(form = null) {
            return axios.post("order", form);
        },
        createPayment(orderId, form = null) {
            return axios.post("order/" + orderId + "/payment", form);
        },
        confirmTrx(address, form = null) {
            return axios.post("payment/" + address + "/confirm", form);
        },
        removeError(index = null) {
            if (index) {
                this.errors.splice(index, 1);
            } else {
                this.errors = [];
            }
        },
        removeMessage(index = null) {
            if (index) {
                this.messages.splice(index, 1);
            } else {
                this.messages = [];
            }
        },
        addMessage({ message, success = false, errors = [] }) {
            if (this.messages.filter((a) => a.message === message).length > 0)
                return;
            this.messages.push({
                message,
                success,
                errors,
            });
        },
        reset() {
            this.messages = [];
        },
    },
});
