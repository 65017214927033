<script setup></script>
<template>
    <div>
        <div>
            <div class="bg-gray-500">
                <div class="content content-full text-center text-white py-5">
                    <div class="my-3">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            fill="currentColor"
                            class="w-16 h-16 mx-auto"
                        >
                            <path
                                d="M320 480H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H192V144c0 26.5 21.5 48 48 48H352V448c0 17.7-14.3 32-32 32zM240 160c-8.8 0-16-7.2-16-16V32.5c2.8 .7 5.4 2.1 7.4 4.2L347.3 152.6c2.1 2.1 3.5 4.6 4.2 7.4H240zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V163.9c0-12.7-5.1-24.9-14.1-33.9L254.1 14.1c-9-9-21.2-14.1-33.9-14.1H64zM80 64c-8.8 0-16 7.2-16 16s7.2 16 16 16h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H80zm0 64c-8.8 0-16 7.2-16 16s7.2 16 16 16h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H80zm54.2 253.8L144 349l16.7 55.6c1.7 5.8 6.6 10.1 12.5 11.2s12-1.3 15.6-6.2l17.6-23.4c1-1.4 2.7-2.2 4.4-2.2c2.1 0 4 1.2 4.9 3l10.1 20.1c2.7 5.4 8.3 8.8 14.3 8.8h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H249.9l-5.6-11.3C237.9 360 224.9 352 210.7 352c-11 0-21.4 4.8-28.5 13.1l-12.6-42.1C166.2 311.7 155.8 304 144 304s-22.2 7.7-25.6 19.1l-14.9 49.5c-2 6.8-8.3 11.4-15.3 11.4H80c-8.8 0-16 7.2-16 16s7.2 16 16 16h8.2c21.2 0 39.9-13.9 46-34.2z"
                            />
                        </svg>
                    </div>
                    <h2 class="mb-0">Terms and Conditions</h2>
                </div>
            </div>
        </div>
        <div class="p-5">
            <div class="rounded bg-white px-5 md:w-1/2 mx-auto p-3">
                <div>
                    <div class="font-bold text-xl">Do's</div>
                    <div class="text-lg font-bold">People Search</div>
                    <ul class="mb-2">
                        <li class="py-1">
                            Find lost love, high-school friends or family
                            members.
                        </li>
                        <li class="py-1">
                            Contact an old university colleague to start a
                            business.
                        </li>
                        <li class="py-1">
                            Find out who is associated with or has a
                            relationship with someone you know.
                        </li>
                    </ul>
                    <div class="text-lg font-bold">Address Search</div>
                    <ul class="mb-2">
                        <li class="py-1">
                            Ensure you're not getting catfishes by verifying the
                            address you’re going to is connected to the person
                            you are talking to.
                        </li>
                        <li class="py-1">
                            Want to buy a house but only know the address? Find
                            contact information including phone and email for
                            the owner.
                        </li>
                    </ul>
                    <div class="text-lg font-bold">Phone Search</div>
                    <ul class="mb-2">
                        <li class="py-1">
                            Put a name to the number: Find out who has been
                            calling you or your family member. Verify the person
                            you've been texting is who they say they area.
                        </li>
                        <li class="py-1">
                            Discover if other people are receiving calls from a
                            phone number using our spam flags remarks.
                        </li>
                    </ul>
                    <hr class="my-2" />
                    <div class="font-bold text-xl">Don't</div>
                    <div class="text-lg font-bold">People Search</div>
                    <ul class="mb-2">
                        <li class="py-1">
                            Stalk, harass, spam or prank call people.
                        </li>
                        <li class="py-1">Steal someone's identity.</li>
                        <li class="py-1">
                            Use the information to conquer the world
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped></style>
