<template>
    <div>
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <h3 class="mt-2 text-lg font-medium leading-6 text-gray-700">
                    Edit User
                </h3>
            </div>
            <div
                class="bg-indigo-800 hover:bg-indigo-900 text-white font-bold py-2 px-4 rounded"
            >
                <router-link :to="{ name: 'admin_user' }" class="text-center">
                    Back To User
                </router-link>
            </div>
        </div>
        <div class="mt-8 flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                    class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
                >
                    <div
                        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                    >
                        <!-- Table Content  -->
                        <main>
                            <div
                                class="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16"
                            >
                                <div
                                    class="overflow-hidden rounded-lg bg-white shadow"
                                >
                                    <div
                                        class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x"
                                    >
                                        <!-- Side section -->
                                        <aside class="py-6 lg:col-span-3">
                                            <nav class="space-y-1">
                                                <a
                                                    href="#"
                                                    class="bg-indigo-50 border-indigo-500 text-indigo-700 hover:bg-indigo-50 hover:text-indigo-700 group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                                                    aria-current="page"
                                                >
                                                    <svg
                                                        class="text-indigo-500 group-hover:text-indigo-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="1.5"
                                                        stroke="currentColor"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                                                        />
                                                    </svg>
                                                    <span class="truncate"
                                                        >Profile</span
                                                    >
                                                </a>
                                            </nav>
                                        </aside>

                                        <form
                                            class="divide-y divide-gray-200 lg:col-span-9"
                                        >
                                            <!-- Profile section -->
                                            <div
                                                class="py-6 px-4 sm:p-6 lg:pb-8"
                                            >
                                                <div>
                                                    <h2
                                                        class="text-lg font-medium leading-6 text-gray-900"
                                                    >
                                                        Profile
                                                    </h2>
                                                    <p
                                                        class="mt-1 text-sm text-gray-500"
                                                    >
                                                        Your information will be
                                                        showed so be careful of
                                                        your username.
                                                    </p>
                                                </div>

                                                <div
                                                    class="mt-6 grid grid-cols-12 gap-6"
                                                >
                                                    <div
                                                        class="col-span-12 sm:col-span-6"
                                                    >
                                                        <label
                                                            for="user_name"
                                                            class="block text-sm font-medium text-gray-700"
                                                            >Username</label
                                                        >
                                                        <input
                                                            id="user_name"
                                                            v-model="
                                                                form.username
                                                            "
                                                            class="mt-1 block w-full rounded-md border border-gray-400 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                                                            name="user_name"
                                                            type="user_name"
                                                        />
                                                    </div>
                                                    <div
                                                        class="col-span-12 sm:col-span-6"
                                                    >
                                                        <label
                                                            for="email"
                                                            class="block text-sm font-medium text-gray-700"
                                                            >Email</label
                                                        >
                                                        <input
                                                            id="email"
                                                            v-model="form.email"
                                                            class="mt-1 block w-full rounded-md border border-gray-400 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                                                            name="email"
                                                            type="email"
                                                        />
                                                    </div>
                                                    <div
                                                        class="col-span-12 sm:col-span-6"
                                                    >
                                                        <label
                                                            for="password"
                                                            class="block text-sm font-medium text-gray-700"
                                                            >Password</label
                                                        >
                                                        <input
                                                            id="password"
                                                            v-model="
                                                                form.password
                                                            "
                                                            class="mt-1 block w-full rounded-md border border-gray-400 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                                                            name="password"
                                                            type="password"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Update section -->
                                            <div
                                                class="divide-y divide-gray-200 pt-6"
                                            >
                                                <div
                                                    class="mt-2 flex justify-end py-4 px-4 sm:px-6"
                                                >
                                                    <button
                                                        type="button"
                                                        class="inline-flex justify-center rounded-md border border-gray-400 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                                                    >
                                                        <router-link
                                                            :to="{
                                                                name: 'admin_user',
                                                            }"
                                                            class="text-center"
                                                        >
                                                            CANCEL
                                                        </router-link>
                                                    </button>
                                                    <button
                                                        @click.prevent="
                                                            admin.updateUser(
                                                                form
                                                            )
                                                        "
                                                        type="button"
                                                        class="ml-5 inline-flex justify-center rounded-md border border-transparent bg-indigo-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                                                    >
                                                        SAVE
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { RouterLink } from "vue-router";
import { useAdminStore } from "@/store/admin.js";
import { reactive } from "vue";
const admin = useAdminStore();
// eslint-disable-next-line no-undef
const props = defineProps({
    id: {
        required: true,
    },
});

let form = reactive({
    id: null,
    username: null,
    email: null,
    password: null,
});

admin.showUser(props.id).then(({ data }) => {
    form.id = data.id;
    form.username = data.username;
    form.email = data.email;
    form.password = data.password;
});
</script>
