<template>
    <div>
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <h3 class="mt-2 text-lg font-medium leading-6 text-gray-700">
                    Order Details
                </h3>
            </div>
            <div
                class="bg-indigo-800 hover:bg-indigo-900 text-white font-bold py-2 px-4 rounded"
            >
                <router-link :to="{ name: 'admin_order' }" class="text-center">
                    Back To Order
                </router-link>
            </div>
        </div>
        <div
            v-for="(order, key) in admin.getOrders.data"
            :key="key"
            class="bg-white"
        >
            <div class="mx-auto max-w-3xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                <div class="sm:flex sm:items-center">
                    <div class="sm:flex-auto">
                        <h3
                            class="mt-2 text-lg font-medium leading-6 text-gray-700"
                        >
                            Order number
                        </h3>
                        <p class="text-indigo-800">{{ order.number }}</p>
                    </div>
                    <div class="mt-4 sm:mt-0 sm:ml-6 sm:flex-none">
                        <button
                            class="rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            type="button"
                        >
                            <router-link
                                :to="{ name: 'admin_addorder' }"
                                class="text-center"
                            >
                                Back To Add Order
                            </router-link>
                        </button>
                    </div>
                </div>

                <div class="mt-10 border-t border-gray-200">
                    <h2 class="sr-only">Your order</h2>

                    <h3 class="sr-only">Items</h3>
                    <!-- Product content -->
                    <div class="flex space-x-6 border-b border-gray-200 py-10">
                        <div class="flex flex-auto justify-between">
                            <div>
                                <h4 class="font-medium text-gray-900">
                                    <p>{{ order.reference }}</p>
                                </h4>

                                <div class="flex">
                                    <dt class="font-medium text-gray-800">
                                        Quantity:
                                    </dt>
                                    <dd class="ml-2 text-gray-800">
                                        {{ order.quantity }}
                                    </dd>
                                </div>
                            </div>
                            <div class="flex flex-col items-end">
                                <dl
                                    class="flex space-x-4 divide-x divide-gray-200"
                                >
                                    <div class="flex pl-4 sm:pl-6">
                                        <dt class="font-medium text-gray-500">
                                            {{ order.price }}
                                        </dt>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>

                    <!-- More product lists -->

                    <!-- Sub total  -->
                    <!-- <dl
            class="space-y-6 border-t border-gray-200 pt-6 font-medium text-gray-500"
          > -->
                    <!-- <div class="flex justify-between">
              <dt>Subtotal</dt>
              <dd class="text-gray-500">{{ order.subtotal }}</dd>
            </div>

            <div class="flex justify-between">
              <dt>Shipping</dt>
              <dd class="text-gray-500">{{ order.shipping }}</dd>
            </div>

            <div class="flex justify-between">
              <dt>Taxes</dt>
              <dd class="text-gray-500">{{ order.tax }}</dd>
            </div> -->

                    <!-- Total -->
                    <!-- <div
              class="flex items-center justify-between border-t border-gray-200 pt-5 text-gray-900"
            >
              <dt class="text-base">Total</dt>
              <dd class="text-base text-gray-500">{{ order.total }}</dd>
            </div> -->
                    <!-- </dl> -->
                    <!-- Customer information -->
                    <dl class="mt-16 grid grid-cols-2 gap-x-4 text-gray-600">
                        <div>
                            <dt class="font-medium text-gray-900">
                                Shipping Address
                            </dt>
                            <dd class="mt-2">
                                <address class="not-italic">
                                    <span class="block">{{
                                        user.username
                                    }}</span>
                                    <span class="block">{{ user.email }}</span>
                                </address>
                            </dd>
                        </div>
                        <div>
                            <dt class="font-medium text-gray-900">
                                Payment Information
                            </dt>
                            <dd
                                class="mt-2 space-y-2 sm:flex sm:space-y-0 sm:space-x-4"
                            >
                                <div class="flex-none">
                                    <svg
                                        aria-hidden="true"
                                        width="36"
                                        height="24"
                                        viewBox="0 0 36 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-6 w-auto"
                                    >
                                        <rect
                                            width="36"
                                            height="24"
                                            rx="4"
                                            fill="#224DBA"
                                        />
                                        <path
                                            d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                                            fill="#fff"
                                        />
                                    </svg>
                                    <p class="sr-only">USDT | TRX</p>
                                </div>
                                <div class="flex-auto">
                                    <p class="text-gray-900">
                                        {{ admin.getUsers.ttl_usdt }}
                                    </p>
                                    <p class="text-gray-900">
                                        {{ admin.getUsers.ttl_trx }}
                                    </p>
                                </div>
                            </dd>
                        </div>
                    </dl>
                    <!-- Next -->
                    <div class="mt-14 border-t border-gray-200 py-6 text-right">
                        <a
                            href="#"
                            class="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                        >
                            <router-link
                                :to="{ name: 'admin_order' }"
                                class="text-center"
                            >
                                Back To Order
                            </router-link>
                            <span aria-hidden="true"> &rarr;</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { RouterLink } from "vue-router";
import { useAdminStore } from "@/store/admin.js";

const admin = useAdminStore();
admin.loadOrder();
admin.loadUser();
</script>
