<template>
    <div class="container mx-auto px-5">
        <div class="border rounded-lg p-5 bg-white">
            <div class="flex justify-between">
                <div
                    class="flex justify-center md:justify-start font-bold items-center uppercase"
                >
                    WELCOME! {{ auth.getUser.username }}
                </div>
            </div>
        </div>
        <div class="border rounded-lg mt-5 px-5 bg-white">
            <span class="block font-bold mt-5 pb-2 border-b border-b-gray-300">
                Top Up History
            </span>
            <div class="flex flex-col overflow-x-auto">
                <table class="min-w-full divide-y divide-gray-300 border my-5">
                    <thead class="h-10 table-fixed">
                        <tr>
                            <th class="w-1/8 px-6 md:px-0 text-sm">Date</th>
                            <th class="w-1/8 px-6 md:px-0 text-sm">Type</th>
                            <th class="w-1/8 px-6 md:px-0 text-sm">Amount</th>
                            <th class="w-1/8 px-6 md:px-0 text-sm">Paid at</th>
                            <th class="w-1/8 px-6 md:px-0 text-sm">
                                Description
                            </th>
                            <th class="w-1/5 px-6 md:px-0 text-sm">Invoice</th>
                            <th class="w-1/8 px-6 md:px-0 text-sm">Status</th>
                        </tr>
                    </thead>
                    <tbody
                        class="divide-y divide-gray-200 bg-white text-center"
                    >
                        <template
                            v-if="
                                main.getTopupOrders &&
                                main.getTopupOrders.data &&
                                main.getTopupOrders.data.length > 0
                            "
                        >
                            <tr
                                v-for="(order, key) in main.getTopupOrders.data"
                                :key="'topup' + key"
                            >
                                <td class="px-3 py-4 text-sm text-gray-500">
                                    {{
                                        moment(order.created_at).format(
                                            "YYYY-MM-DD"
                                        )
                                    }}
                                </td>
                                <td class="px-3 py-4 text-sm text-gray-500">
                                    {{
                                        order.payment
                                            ? order.payment.paid_type ?? "N/A"
                                            : "N/A"
                                    }}
                                </td>
                                <td class="px-3 py-4 text-sm text-gray-500">
                                    {{ order.total }}
                                </td>
                                <td class="px-3 py-4 text-sm text-gray-500">
                                    {{
                                        moment(order.paid_at).format(
                                            "YYYY-MM-DD"
                                        )
                                    }}
                                </td>
                                <td class="px-3 py-4 text-sm text-gray-500">
                                    {{
                                        order.packages.length > 0
                                            ? order.packages[0].desc
                                            : "N/A"
                                    }}
                                </td>
                                <td class="py-3 py-4 text-sm text-gray-500">
                                    <a
                                        href="#"
                                        @click.prevent="
                                            main.downloadInvoice(
                                                order.reference
                                            )
                                        "
                                        class="text-indigo-600 hover:text-indigo-900"
                                    >
                                        Download
                                        <span class="sr-only"> Invoice </span>
                                    </a>
                                </td>

                                <td class="px-3 py-4 text-sm text-gray-500">
                                    {{ getStatus(order.status) }}
                                </td>
                            </tr>
                        </template>
                        <tr v-else>
                            <th colspan="7" class="py-3 text-gray-500">
                                No Result
                            </th>
                        </tr>
                    </tbody>
                </table>
                <Pagination
                    :data="main.getTopupOrders"
                    @change="changePageTopup"
                />
            </div>
        </div>
        <!-- Use credit to purchase packages -->
        <div class="border rounded-lg mt-5 px-5 bg-white">
            <span class="block font-bold mt-5 pb-2 border-b border-b-gray-300">
                Purchase History
            </span>
            <div class="flex flex-col overflow-x-auto">
                <table class="min-w-full divide-y divide-gray-300 border my-5">
                    <thead class="h-10 table-fixed">
                        <tr>
                            <th class="w-1/5 px-6 md:px-0 text-sm">Date</th>
                            <th class="w-1/5 px-6 md:px-0 text-sm">Type</th>
                            <th class="w-1/5 px-6 md:px-0 text-sm">Content</th>
                            <th class="w-1/5 px-6 md:px-0 text-sm">xCoin</th>
                            <!-- <th class="w-1/5 px-6 md:px-0 text-sm">View</th> -->
                        </tr>
                    </thead>
                    <tbody
                        class="divide-y divide-gray-200 bg-white text-center"
                    >
                        <template
                            v-if="
                                main.getPurchaseOrders &&
                                main.getPurchaseOrders.data &&
                                main.getPurchaseOrders.data.length > 0
                            "
                        >
                            <tr
                                v-for="(order, key) in main.getPurchaseOrders
                                    .data"
                                :key="'purchase' + key"
                            >
                                <td class="px-3 py-4 text-sm text-gray-500">
                                    {{
                                        moment(order.created_at).format(
                                            "YYYY-MM-DD"
                                        )
                                    }}
                                </td>
                                <td
                                    class="px-3 py-4 text-sm text-gray-500 capitalize"
                                >
                                    {{ order.type }}
                                </td>
                                <td
                                    class="px-3 py-4 text-sm text-gray-500 uppercase"
                                >
                                    {{ order.remark }}
                                </td>
                                <td class="px-3 py-4 text-sm text-gray-500">
                                    {{ order.total }}
                                </td>
                            </tr>
                        </template>
                        <tr v-else>
                            <th colspan="4" class="py-3 text-gray-500">
                                No Result
                            </th>
                        </tr>
                    </tbody>
                </table>
                <Pagination
                    :data="main.getPurchaseOrders"
                    @change="changePagePurchase"
                />
            </div>
        </div>
    </div>
</template>
<script setup>
// import { RouterLink } from "vue-router";
import Pagination from "@/components/Pagination.vue";
import { useAuthStore, useMainStore } from "@/store/index.js";
import moment from "moment";
import { ref } from "vue";

const auth = useAuthStore();
const main = useMainStore();
const getStatus = (code) => {
    switch (code) {
        case -1:
            return "Failed";
        case 0:
            return "New";
        case 1:
            return "Pending";
        case 2:
            return "Success";
        default:
            return "Unknown";
    }
};
let xsc_page = ref(main.getPurchaseOrders.current_page ?? 0);
let cash_page = ref(main.getTopupOrders.current_page ?? 0);
const changePagePurchase = (page) => {
    xsc_page.value = page;
    main.loadOrder({ xsc_page: page, cash_page });
};
const changePageTopup = (page) => {
    cash_page.value = page;
    main.loadOrder({ cash_page: page, xsc_page });
};
main.loadOrder();
</script>
