<template>
    <div class="w-10/12 mx-auto">
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <h1 class="text-xl font-semibold text-gray-900">Overview</h1>
            </div>
            <div class="sm:flex sm:items-center mt-4">
                <div
                    class="bg-indigo-800 hover:bg-indigo-900 text-white font-bold py-2 px-4 rounded"
                >
                    <router-link :to="{ name: 'search' }" class="text-center">
                        Back To Search
                    </router-link>
                </div>
            </div>
        </div>
        <div class="mt-8 bg-white px-3.5 py-3">
            <label class="text-xl font-medium text-gray-900">Filter by:</label>
            <fieldset class="mt-4">
                <div
                    class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
                >
                    <div class="flex items-center">
                        <input
                            @change="filterReport"
                            id="daily"
                            v-model="filter.order_by"
                            name="months"
                            value="daily"
                            type="radio"
                            checked
                            class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                        <label
                            for="daily"
                            class="ml-3 block text-sm font-medium text-gray-700"
                        >
                            Daily
                        </label>
                    </div>
                    <div class="flex items-center">
                        <input
                            @change="filterReport"
                            id="weekly"
                            v-model="filter.order_by"
                            name="months"
                            value="weekly"
                            type="radio"
                            class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                        <label
                            for="weekly"
                            class="ml-3 block text-sm font-medium text-gray-700"
                        >
                            Weekly
                        </label>
                    </div>
                    <div class="flex items-center">
                        <input
                            @change="filterReport"
                            id="monthly"
                            v-model="filter.order_by"
                            name="months"
                            type="radio"
                            value="monthly"
                            class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                        <label
                            for="monthly"
                            class="ml-3 block text-sm font-medium text-gray-700"
                        >
                            Monthly
                        </label>
                    </div>
                </div>
            </fieldset>
            <div class="divide-y divide-gray-200 pt-6">
                <div class="mt-2 flex justify-end py-4 px-4 sm:px-6">
                    <button
                        @click="filterReport"
                        type="button"
                        class="ml-5 inline-flex justify-center rounded-md border border-transparent bg-indigo-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    >
                        FILTER
                    </button>
                </div>
            </div>
        </div>
        <div class="mt-6 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <!-- 3 Card -->
            <div
                class="border border-gray-300 overflow-hidden rounded-lg bg-white shadow"
            >
                <div class="p-5">
                    <div class="flex items-center">
                        <div class="flex-shrink-0">
                            <svg
                                class="w-20 h-20"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="1.5"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <div class="ml-5 w-0 flex-1">
                            <dl>
                                <dt
                                    class="text-2xl font-medium text-orange-500"
                                >
                                    Total Orders
                                </dt>
                                <dd>
                                    <div
                                        class="text-2xl font-medium text-gray-900"
                                    >
                                        {{ admin.getReports.order_count }}
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="border border-gray-300 overflow-hidden rounded-lg bg-white shadow"
            >
                <div class="p-5">
                    <div class="flex items-center">
                        <div class="flex-shrink-0">
                            <svg
                                class="w-20 h-20"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="1.5"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <div class="ml-5 w-0 flex-1">
                            <dl>
                                <dt class="text-2xl font-medium text-red-500">
                                    Total Users
                                </dt>
                                <dd>
                                    <div
                                        class="text-2xl font-medium text-gray-900"
                                    >
                                        {{ admin.getReports.ttl_cust }}
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="border border-gray-300 overflow-hidden rounded-lg bg-white shadow"
            >
                <div class="p-5">
                    <div class="flex items-center">
                        <div class="flex-shrink-0">
                            <svg
                                class="w-20 h-20"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="1.5"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <div class="ml-5 w-0 flex-1">
                            <div>
                                <div class="flex justify-around text-center">
                                    <div class="w-1/2">
                                        <div
                                            class="text-xl font-medium text-indigo-800"
                                        >
                                            Total USDT
                                        </div>
                                        <div
                                            class="text-2xl font-medium text-gray-900"
                                        >
                                            {{ admin.getReports.ttl_usdt }}
                                        </div>
                                        <div
                                            class="text-sm font-bold text-green-800"
                                        >
                                            PAID Amount
                                        </div>
                                        <div
                                            class="text-2xl font-medium text-gray-900"
                                        >
                                            {{ admin.getReports.paid_ttl_usdt }}
                                        </div>
                                    </div>
                                    <div class="w-1/2">
                                        <div
                                            class="text-xl font-medium text-indigo-800"
                                        >
                                            Total TRX
                                        </div>
                                        <div
                                            class="text-2xl font-medium text-gray-900"
                                        >
                                            {{ admin.getReports.ttl_trx }}
                                        </div>

                                        <div
                                            class="text-sm font-bold text-green-800"
                                        >
                                            PAID Amount
                                        </div>
                                        <div
                                            class="text-2xl font-medium text-gray-900"
                                        >
                                            {{ admin.getReports.paid_ttl_trx }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-10/12 mx-auto">
        <!-- List of table -->
        <div>
            <!-- Content  -->
            <div class="mt-6 border border-gray-300 rounded p-5 bg-white">
                <h1 class="font-bold text-gray-700">Filter Result</h1>
                <div class="flex flex-row flex-wrap mt-5">
                    <div class="w-full md:w-1/4 px-3 py-2 md:py-0">
                        <label
                            class="block text-sm font-medium text-gray-700"
                            for="date"
                        >
                            Date
                        </label>
                        <x-date
                            v-model="filter.date"
                            range
                            timezone="Asia/Kuala_Lumpur"
                            :enable-time-picker="false"
                            :format="format"
                        ></x-date>
                    </div>
                    <div class="w-full md:w-1/4 px-3 py-2 md:py-0">
                        <label
                            class="block text-sm font-medium text-gray-700"
                            for="trans_type"
                        >
                            Packages
                        </label>
                        <select
                            v-model="filter.package"
                            id="trans_type"
                            class="mt-1 block w-full border rounded-md border-gray-300 py-2 pl-3 pr-3 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="product_type"
                        >
                            <option selected :value="null">All</option>
                            <option
                                v-for="(name, value) in admin.getReports
                                    .packages"
                                :value="value"
                                :key="value"
                            >
                                {{ name }}
                            </option>
                        </select>
                    </div>
                    <div class="w-full md:w-1/4 px-3 py-2 md:py-0">
                        <label
                            class="block text-sm font-medium text-gray-700"
                            for="trans_status"
                        >
                            Status
                        </label>
                        <select
                            v-model="filter.status"
                            id="trans_status"
                            class="mt-1 block w-full border rounded-md border-gray-300 py-2 pl-3 pr-3 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="trans_status"
                        >
                            <option selected :value="null">All</option>
                            <option :value="1">Paid</option>
                            <option :value="0">UnPaid</option>
                        </select>
                    </div>
                </div>
                <div class="flex flex-row flex-wrap mt-5 gap-2">
                    <a
                        class="border rounded px-5 py-2 font-semibold text-white bg-indigo-600"
                        href="#"
                        @click.prevent="filterReport"
                    >
                        Filter
                    </a>
                    <a
                        class="border rounded px-6 py-2 font-semibold text-white bg-orange-400"
                        href="#"
                        @click.prevent="reset"
                    >
                        Reset
                    </a>
                </div>
            </div>
            <div class="sm:flex sm:items-center mt-4">
                <div class="sm:flex-auto">
                    <h1 class="text-xl font-semibold text-gray-900">Report</h1>
                </div>
            </div>
            <div class="mt-8 flex flex-col">
                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div
                        class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
                    >
                        <div
                            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                        >
                            <table
                                class="border border-gray-200 min-w-full divide-y divide-gray-300"
                            >
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th
                                            class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                            scope="col"
                                        >
                                            Order ID
                                        </th>
                                        <th
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            Date
                                        </th>
                                        <th
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            Status
                                        </th>
                                        <th
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            Username
                                        </th>
                                        <th
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            Plan
                                        </th>
                                        <th
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            Package
                                        </th>
                                        <th
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            XSC
                                        </th>
                                        <th
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            Subtotal
                                        </th>
                                        <th
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            Total
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    class="divide-y divide-gray-200 bg-white"
                                    v-if="admin.getReports.orders"
                                >
                                    <tr
                                        v-for="(order, key) in admin.getReports
                                            .orders.data"
                                        :key="key"
                                    >
                                        <td
                                            class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6"
                                        >
                                            {{ order.id }}
                                        </td>
                                        <td
                                            class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900"
                                        >
                                            {{
                                                moment(
                                                    order.created_at,
                                                    "YYYY-MM-DDThh:mm:ssTZD",
                                                    true
                                                ).format("DD-MM-YYYY")
                                            }}
                                        </td>
                                        <td
                                            class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900"
                                        >
                                            {{ getStatus(order.status) }}
                                        </td>
                                        <td
                                            class="whitespace-nowrap px-2 py-2 text-sm text-gray-900"
                                        >
                                            {{ order.user.username }}
                                        </td>
                                        <td
                                            class="whitespace-nowrap px-2 py-2 text-sm text-gray-500"
                                        >
                                            {{
                                                order.packages &&
                                                order.packages.length > 0
                                                    ? order.packages[0].name
                                                    : "N/A"
                                            }}
                                        </td>
                                        <td
                                            class="whitespace-nowrap px-2 py-2 text-sm text-gray-500"
                                        >
                                            {{
                                                order.packages &&
                                                order.packages.length > 0
                                                    ? order.packages[0].free_xsc
                                                    : "N/A"
                                            }}
                                        </td>
                                        <td
                                            class="whitespace-nowrap uppercase px-2 py-2 text-sm text-gray-500"
                                        >
                                            {{
                                                order.trx_subtotal ??
                                                order.usdt_subtotal
                                            }}
                                        </td>
                                        <td
                                            class="whitespace-nowrap px-2 py-2 text-sm text-gray-500"
                                        >
                                            {{
                                                order.trx_total ??
                                                order.usdt_total
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Pagination
                                :data="admin.getReports.orders"
                                @change="changePage"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { RouterLink } from "vue-router";
import { useAdminStore } from "@/store/admin.js";
import { reactive } from "vue";
import moment from "moment";

const admin = useAdminStore();
admin.loadReport();
const filter = reactive({
    status: null,
    package: null,
    date: null,
    order_by: null,
    page: null,
});
const filterReport = () => {
    admin.loadReport(filter);
};

const changePage = (page) => {
    filter.page = page;
    admin.loadReport(filter);
};
const format = (date) => {
    const form = {
        day: null,
        month: null,
        year: null,
    };
    const dateForm = [];
    return date
        .map((date, key) => {
            if (date) {
                form.day = date.getDate();
                form.month = date.getMonth() + 1;
                form.year = date.getFullYear();
                dateForm.push(form);

                return `${form.day}/${form.month}/${form.year}`;
            }
            return `${dateForm[key - 1].day}/${dateForm[key - 1].month}/${
                dateForm[key - 1].year
            }`;
        })
        .join("-");
};
const reset = () => {
    filter.status = null;
    filter.package = null;
    filter.date = null;
};

const getStatus = (status) => {
    switch (status) {
        case 0:
            return "NEW";
        case 1:
            return "PENDING";
        case 2:
            return "PAID";
    }
};
</script>
