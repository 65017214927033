<template>
    <div>
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <h3 class="mt-2 text-lg font-medium leading-6 text-gray-700">
                    Add Order
                </h3>
            </div>
            <div
                class="bg-indigo-800 hover:bg-indigo-900 text-white font-bold py-2 px-4 rounded"
            >
                <router-link :to="{ name: 'admin_order' }" class="text-center">
                    Back To Order
                </router-link>
            </div>
        </div>
        <div class="mt-8 flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                    class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
                >
                    <div
                        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                    >
                        <!-- Table Content  -->
                        <main>
                            <div
                                class="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16"
                            >
                                <div
                                    class="overflow-hidden rounded-lg bg-white shadow"
                                >
                                    <div class="divide-y divide-gray-200 l">
                                        <!-- Order section -->
                                        <div class="py-6 px-4 sm:p-6 lg:pb-8">
                                            <div>
                                                <h2
                                                    class="text-lg font-medium leading-6 text-gray-900"
                                                >
                                                    Orders
                                                </h2>
                                            </div>
                                            <div
                                                class="mx-auto w-full md:w-1/2 flex flex-col gap-3 mt-10 col-span-12 sm:col-span-6"
                                            >
                                                Package's Name
                                                <input
                                                    id="package_name"
                                                    v-model="form.name"
                                                    class="block w-full sm:text-sm rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-500 focus-within:ring-blue-500"
                                                    name="package_name"
                                                    placeholder="Package Name"
                                                    type="package_name"
                                                />
                                                Order's Number
                                                <input
                                                    id="order_reference"
                                                    v-model="form.reference"
                                                    class="block w-full sm:text-sm rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-500 focus-within:ring-blue-500"
                                                    name="order_reference"
                                                    placeholder="Order's Number"
                                                    type="text"
                                                />
                                                Created on
                                                <input
                                                    id="created_at"
                                                    v-model="form.created_at"
                                                    class="block w-full sm:text-sm rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-500 focus-within:ring-blue-500"
                                                    name="created_at"
                                                    placeholder="Created on"
                                                    type="date"
                                                />
                                                Quantity
                                                <input
                                                    id="order_quantity"
                                                    v-model="form.quantity"
                                                    class="block w-full sm:text-sm rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-500 focus-within:ring-blue-500"
                                                    name="order_quantity"
                                                    placeholder="Quantity"
                                                    type="number"
                                                />
                                                Order's Price
                                                <input
                                                    id="price"
                                                    v-model="form.price"
                                                    class="block w-full sm:text-sm rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-500 focus-within:ring-blue-500"
                                                    name="price"
                                                    placeholder="Price (RM)"
                                                    type="price"
                                                />
                                            </div>
                                        </div>

                                        <!-- Update section -->
                                        <div
                                            class="divide-y divide-gray-200 pt-6"
                                        >
                                            <div
                                                class="mt-2 flex justify-end py-4 px-4 sm:px-6"
                                            >
                                                <button
                                                    type="button"
                                                    class="inline-flex justify-center rounded-md border border-gray-400 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                                                >
                                                    <router-link
                                                        :to="{
                                                            name: 'admin_order',
                                                        }"
                                                        class="text-center"
                                                    >
                                                        CANCEL
                                                    </router-link>
                                                </button>
                                                <button
                                                    @click.prevent="
                                                        admin.createOrder(form)
                                                    "
                                                    type="button"
                                                    class="ml-5 inline-flex justify-center rounded-md border border-transparent bg-indigo-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                                                >
                                                    SAVE
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { RouterLink } from "vue-router";
import { useAdminStore } from "@/store/admin.js";
import { reactive } from "vue";
const admin = useAdminStore();
const form = reactive({
    name: null,
    reference: null,
    created_at: null,
    quantity: null,
    price: null,
});
</script>
