<template>
    <div>
        <div class="mt-8 flex flex-col w-10/12 mx-auto">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                    class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
                >
                    <div
                        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                    >
                        <!-- Table Content  -->
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-100">
                                <tr
                                    class="text-center text-sm font-semibold text-gray-900"
                                >
                                    <th
                                        class="py-3.5 pl-4 pr-3 sm:pl-6"
                                        scope="col"
                                    >
                                        Username
                                    </th>
                                    <th class="px-3 py-3.5" scope="col">XSC</th>
                                    <th class="px-3 py-3.5" scope="col">
                                        Password
                                    </th>
                                    <th class="px-3 py-3.5" scope="col">
                                        Role
                                    </th>
                                    <th class="px-3 py-3.5" scope="col">
                                        Package
                                    </th>
                                    <th class="px-3 py-3.5" scope="col">
                                        Register At
                                    </th>
                                    <th class="px-3 py-3.5" scope="col">
                                        Delete
                                    </th>
                                    <th class="px-3 py-3.5" scope="col">
                                        Renew
                                    </th>
                                </tr>
                            </thead>
                            <!-- Users -->
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr
                                    v-for="(user, key) in admin.getUsers.data"
                                    :key="key"
                                    class="divide-x divide-gray-200"
                                >
                                    <td
                                        class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6"
                                    >
                                        <div class="flex items-center">
                                            <div class="ml-4">
                                                <div
                                                    class="font-medium text-gray-900"
                                                >
                                                    {{ user.username }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6"
                                    >
                                        <div class="flex items-center">
                                            <div class="ml-4">
                                                <div
                                                    class="font-medium text-gray-900"
                                                >
                                                    {{ user.xsc }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                    >
                                        <button
                                            class="rounded text-white bg-blue-500 hover:bg-blue-600 px-2 py-2 font-bold"
                                        >
                                            Reset Password
                                        </button>
                                    </td>
                                    <td
                                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                    >
                                        <div class="text-gray-900">
                                            {{
                                                user.is_admin ? "Admin" : "User"
                                            }}
                                        </div>
                                    </td>
                                    <td
                                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                    >
                                        <div
                                            class="text-gray-900"
                                            v-html="getPackages(user)"
                                        ></div>
                                    </td>
                                    <td
                                        class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                    >
                                        <div class="text-gray-900">
                                            {{ main.getDate(user.created_at) }}
                                        </div>
                                    </td>
                                    <td class="py-2 flex justify-center gap-4">
                                        <button
                                            @click.prevent="
                                                admin.deleteUser(user)
                                            "
                                            class="rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-auto"
                                            type="button"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                    <td class="py-2 flex justify-center gap-4">
                                        <button
                                            v-if="
                                                user.package &&
                                                user.packages.length < 1
                                            "
                                            @click.prevent="
                                                admin.renewUser(user)
                                            "
                                            class="rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-auto"
                                            type="button"
                                        >
                                            Renew
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination
                            :data="admin.getUsers"
                            @change="changePage"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useAdminStore } from "@/store/admin.js";
import { useMainStore } from "@/store/index.js";

const admin = useAdminStore();
const main = useMainStore();
admin.loadUser();
const changePage = (page) => {
    admin.loadUser({ page });
};
const getPackages = (user) => {
    const packages = user.packages;
    if (packages.length > 0) {
        const packageNames = [];
        for (let i = 0; i < packages.length; i++) {
            const p = packages[i];
            if (i === 0) {
                packageNames.push(
                    `<span class="font-bold
						${main.isExpiredDate(p.pivot.expired_at) ? "text-red-600" : "text-green-600"}
						">
						${p.name} [${main.getDate(p.pivot.expired_at)}]
					 </span>`
                );
            } else {
                packageNames.push(p.name);
            }
        }
        return packageNames.join(", ");
    }
    return "N/A";
};
</script>
