<template>
    <div class="container mx-auto px-5">
        <div class="border rounded-lg p-5 bg-white">
            <main class="mx-auto max-w-7xl my-10">
                <div class="mx-auto flex flex-col gap-2 text-center">
                    <h1
                        class="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl"
                    >
                        <span
                            class="block xl:inline bg-[url('@/assets/malaysia-flag.jpg')] bg-clip-text text-transparent bg-contain"
                        >
                            xSearch
                        </span>
                    </h1>
                    <p
                        class="mx-auto mt-3 max-w-md text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-3xl md:text-2xl mb-3"
                    >
                        Welcome, Please Login.
                    </p>
                </div>
                <div class="mx-auto w-full md:w-1/2 flex flex-col gap-3">
                    <input
                        id="name"
                        v-model="form.username"
                        class="block w-full sm:text-sm rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-500 focus-within:ring-blue-500"
                        name="name"
                        placeholder="Username"
                        type="text"
                        @keyup.enter="loginAction"
                    />
                    <input
                        id="password"
                        v-model="form.password"
                        class="block w-full sm:text-sm rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-500 focus-within:ring-blue-500"
                        name="password"
                        placeholder="Password"
                        type="password"
                        @keyup.enter="loginAction"
                    />
                    <button
                        class="w-full rounded-md border border-blue-500 bg-white px-4 py-2 text-sm font-medium text-blue-500 hover:bg-blue-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        type="button"
                        @click="loginAction"
                    >
                        <span v-if="main.isLoading" class="text-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                class="w-6 h-6 animate-spin mx-auto"
                            >
                                <path
                                    class="fa-primary"
                                    d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM96 256A48 48 0 1 0 0 256a48 48 0 1 0 96 0zM75 142.9A48 48 0 1 0 142.9 75 48 48 0 1 0 75 142.9z"
                                />
                                <path
                                    class="fa-secondary"
                                    d="M369.1 75A48 48 0 1 1 437 142.9 48 48 0 1 1 369.1 75zM416 256a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM208 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM75 369.1A48 48 0 1 1 142.9 437 48 48 0 1 1 75 369.1zm294.2 0A48 48 0 1 1 437 437a48 48 0 1 1 -67.9-67.9z"
                                />
                            </svg>
                        </span>
                        <span v-else>LOGIN</span>
                    </button>
                </div>
            </main>
        </div>
        <div class="container mx-auto mt-5">
            <div class="border rounded-lg px-5 py-10 bg-white">
                <div class="text-center">
                    <h1
                        class="text-md md:text-4xl font-bold tracking-tight text-gray-700 md:text-1xl"
                    >
                        <span class="block xl:inline"
                            >DISCLAIMER & ACCEPTANCE</span
                        >
                    </h1>
                </div>
                <p
                    class="mx-auto text-sm md:text-md text-gray-400 mt-5 md:max-w-xl text-justify"
                >
                    By Sign in to xSearch.Pro, I acknowledge and agree that I
                    will not use any of the information provided by xSearch.Pro
                    for purposes of determining any entity or person's
                    eligibility for credit, insurance, employment, housing, or
                    for any other purposes covered under the Credit Reporting
                    Agencies Act 2010 (CRA Act).
                </p>
            </div>
        </div>
    </div>
</template>
<style>
.fa-secondary {
    opacity: 0.4;
}
</style>
<script setup>
import { useReCaptcha } from "vue-recaptcha-v3";
import { reactive } from "vue";
import { useAuthStore, useMainStore } from "@/store/index.js";

const { recaptchaLoaded, executeRecaptcha } = useReCaptcha();
const form = reactive({
    username: null,
    password: null,
});
const main = useMainStore();
const loginAction = async () => {
    if (!main.isLoading) {
        main.setLoading(true);
        await recaptchaLoaded();

        // Execute reCAPTCHA with action "login".
        const token = await executeRecaptcha("login");
        form.rc = "login";
        form.token = token;

        const auth = useAuthStore();
        main.setBotAction(auth.login, [form]);
        auth.login(form);
    }
};
</script>
