<template>
    <div class="container mx-auto px-5">
        <div class="border rounded p-5 bg-white">
            <div class="flex justify-between items-center">
                <div>
                    <strong>Payment</strong>
                </div>
                <router-link
                    :to="{ name: 'search' }"
                    class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded text-center"
                >
                    Back To Search
                </router-link>
            </div>
        </div>
        <div class="border rounded-md mt-5 bg-white py-5">
            <div
                class="border rounded-md py-3 mx-5 flex items-center justify-center text-center bg-gray-100"
            >
                <h1 class="text-lg font-bold">
                    <span v-if="auth.user.package === null">STARTUP </span>
                    <span v-else>RENEW / UPGRADE</span>
                    PACKAGE
                </h1>
            </div>
            <div
                v-if="auth.getUser && auth.getUser.package"
                class="font-bold mx-5 text-center pt-2"
            >
                <div>
                    <span>Current Package : </span>
                    {{
                        auth.getUser.package ? auth.getUser.package.name : "N/A"
                    }}
                </div>
                ( Expired At:&nbsp;
                <span
                    class="font-bold"
                    :class="{
                        'text-red-600': main.isExpiredDate(
                            auth.getUser.packages[0].pivot.expired_at
                        ),
                        'text-green-600': !main.isExpiredDate(
                            auth.getUser.packages[0].pivot.expired_at
                        ),
                    }"
                >
                    {{
                        auth.getUser.package.id < 3
                            ? "N/A"
                            : main.getDate(
                                  auth.getUser.packages[0].pivot.expired_at
                              )
                    }}
                </span>
                )
            </div>
            <div
                class="flex mx-5 flex-wrap gap-5 justify-between text-center mt-3"
            >
                <template v-if="auth.user.package === null">
                    <div
                        v-for="(packages, key) in main.getPackages"
                        :key="key"
                        class="border rounded md:flex-1 w-full md:w-auto p-3 my-2"
                    >
                        <div class="">
                            <h1 class="font-bold text-lg mb-5">
                                {{ packages.name }}
                            </h1>
                            <p v-if="packages.desc" class="h-10">
                                {{ packages.desc }}
                                <span class="block text-sm font-bold">
                                    <template
                                        v-if="
                                            packages.renew !== null &&
                                            packages.renew > 0
                                        "
                                    >
                                        {{
                                            "(Renew Monthly On RM " +
                                            packages.renew +
                                            ")"
                                        }}
                                    </template>
                                    <template v-else> (LIFE TIME) </template>
                                </span>
                            </p>
                            <p v-if="packages.free_xsc" class="font-bold mt-2">
                                Get {{ packages.free_xsc }} xCoin
                            </p>
                            <p :key="key" class="font-bold">
                                {{ packages.data_price }} xCoin / Data
                            </p>
                            <p :key="key" class="font-bold mt-5">
                                RM {{ packages.myr.total }}
                            </p>
                        </div>
                        <button
                            :class="{
                                'bg-green-600 hover:bg-green-700':
                                    packages.id === form.package_id,
                                'bg-gray-500 hover:bg-gray-700':
                                    packages.id !== form.package_id,
                            }"
                            class="w-full mt-5 text-white font-bold py-2 px-4 rounded"
                            @click.prevent="selectPackage(packages)"
                        >
                            {{
                                packages.id === form.package_id
                                    ? "SELECTED"
                                    : "SELECT"
                            }}
                        </button>
                    </div>
                </template>
                <template v-else-if="main.getUpgradePackage !== null">
                    <div
                        class="border rounded md:flex-1 w-full md:w-auto p-3 my-2"
                    >
                        <div class="">
                            <h1 class="font-bold text-lg mb-5">
                                <span
                                    v-if="
                                        auth.user.package.id !==
                                        main.getUpgradePackage.id
                                    "
                                >
                                    Upgrade to
                                </span>
                                {{ main.getUpgradePackage.name }}
                            </h1>
                            <p v-if="main.getUpgradePackage.desc" class="h-10">
                                {{ main.getUpgradePackage.desc }}
                                <span class="block text-sm font-bold">
                                    <template
                                        v-if="
                                            main.getUpgradePackage.renew !==
                                                null &&
                                            main.getUpgradePackage.renew > 0
                                        "
                                    >
                                        {{
                                            "(Renew Monthly On RM " +
                                            main.getUpgradePackage.renew +
                                            ")"
                                        }}
                                    </template>
                                </span>
                            </p>
                            <p class="font-bold mt-5">
                                RM {{ main.getUpgradePackage.renew }}
                            </p>
                            <Switch
                                type="button"
                                v-model="form.renew"
                                @update:modelValue="resetForm"
                                class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                :class="{
                                    'bg-gray-200': !form.renew,
                                    'bg-indigo-600': form.renew,
                                }"
                                role="switch"
                                aria-checked="false"
                                aria-labelledby="annual-billing-label"
                            >
                                <transition>
                                    <span
                                        aria-hidden="true"
                                        class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                        :class="{
                                            'translate-x-0': !form.renew,
                                            'translate-x-5': form.renew,
                                        }"
                                    ></span>
                                </transition>
                            </Switch>
                        </div>
                    </div>
                    <div
                        class="border rounded md:flex-1 w-full md:w-auto p-3 my-2"
                    >
                        <h1 class="font-bold text-lg mb-5">TOPUP xCoin</h1>
                        <input
                            id="xcoin"
                            v-model="form.coin"
                            class="block w-full sm:text-sm rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-500 focus-within:ring-blue-500"
                            name="xcoin"
                            placeholder="xCoin"
                            @keydown="resetForm"
                            type="text"
                        />
                    </div>
                    <button
                        class="w-full mt-5 text-white font-bold py-2 px-4 rounded bg-green-600 hover:bg-green-700"
                        @click.prevent="selectPackage(main.getUpgradePackage)"
                    >
                        Pay Now
                    </button>
                </template>
            </div>
            <div v-if="form.myr > 0">
                <div v-if="main.isLoading">
                    <div
                        class="w-full text-xl font-bold text-orange-500 text-center my-5"
                    >
                        <label>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 512 512"
                                class="animate-spin inline-block"
                            >
                                <path
                                    class="fa-primary"
                                    d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM96 256A48 48 0 1 0 0 256a48 48 0 1 0 96 0zM75 142.9A48 48 0 1 0 142.9 75 48 48 0 1 0 75 142.9z"
                                />
                                <path
                                    class="fa-secondary"
                                    d="M369.1 75A48 48 0 1 1 437 142.9 48 48 0 1 1 369.1 75zM416 256a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM208 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM75 369.1A48 48 0 1 1 142.9 437 48 48 0 1 1 75 369.1zm294.2 0A48 48 0 1 1 437 437a48 48 0 1 1 -67.9-67.9z"
                                />
                            </svg>
                            Please wait...
                        </label>
                    </div>
                </div>
                <template v-else>
                    <div
                        class="border rounded-md py-3 mx-5 flex items-center justify-center text-center bg-gray-100 mt-5"
                    >
                        <h1 class="text-lg font-bold">TOP UP GATEWAY</h1>
                    </div>
                    <div
                        class="flex md:flex-auto mx-5 flex-wrap gap-5 justify-between text-center mt-3"
                    >
                        <div
                            class="border rounded md:flex-1 flex flex-col w-full md:w-auto p-3 my-2"
                        >
                            <div class="flex-grow">
                                <h1 class="font-bold text-lg mb-5">USDT</h1>
                                <div class="flex justify-center">
                                    <img
                                        class="w-20 h-20"
                                        src="img/logo/bitcoin-logo.png"
                                    />
                                </div>
                                <div class="w-1/2 mx-auto text-center">
                                    <!--
									<p class="my-2">
										<span class="font-bold">
											Subtotal :
										</span>
										{{ form.usdt }} USDT
									</p>
									<p class="my-2">
										<span class="font-bold">
											Processing Fee :
										</span>
										{{ form.usdt_tax }} USDT
									</p>
									-->
                                    <p class="mt-10 font-bold">
                                        <!--
										<span class="font-bold">Total :</span>
										-->
                                        {{ form.usdt_total }} USDT
                                    </p>
                                </div>
                            </div>
                            <a
                                @click.prevent="payNow('crypto')"
                                href="#"
                                class="block text-center mt-5 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                            >
                                <span v-if="loadingPayment">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        class="w-10 h-10 animate-spin mx-auto"
                                    >
                                        <path
                                            fill="rgba(31, 41, 55, 1)"
                                            d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z"
                                        />
                                        <path
                                            fill="#cbd5e0"
                                            d="M256 64C150 64 64 150 64 256s86 192 192 192c70.1 0 131.3-37.5 164.9-93.6l.1 .1c-6.9 14.9-1.5 32.8 13 41.2c15.3 8.9 34.9 3.6 43.7-11.7c.2-.3 .4-.6 .5-.9l0 0C434.1 460.1 351.1 512 256 512C114.6 512 0 397.4 0 256S114.6 0 256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32z"
                                        />
                                    </svg>
                                </span>
                                <span v-else>TOP UP</span>
                            </a>
                        </div>
                        <div
                            class="border rounded md:flex-1 flex flex-col w-full md:w-auto p-3 my-2"
                        >
                            <div class="flex-grow">
                                <h1 class="font-bold text-lg mb-5">FPAY</h1>
                                <div class="flex justify-center">
                                    <img
                                        class="w-20 h-20"
                                        src="img/logo/fpay.png"
                                    />
                                </div>
                                <div class="w-1/2 mx-auto text-center">
                                    <!--
									<p class="my-2">
										<span class="font-bold">
											Subtotal :
										</span>
										RM {{ form.myr }}
									</p>
									<p class="my-2">
										<span class="font-bold">
											Processing Fee :
										</span>
										RM {{ form.myr_tax }}
									</p>
									-->
                                    <p class="mt-10 font-bold">
                                        <!--
										<span class="font-bold">Total :</span>
										-->
                                        RM {{ form.myr_total }}
                                    </p>
                                </div>
                            </div>
                            <a
                                @click.prevent="payNow('fpay')"
                                href="#"
                                class="block text-center mt-5 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                            >
                                <span v-if="loadingPayment">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        class="w-10 h-10 animate-spin mx-auto"
                                    >
                                        <path
                                            fill="rgba(31, 41, 55, 1)"
                                            d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z"
                                        />
                                        <path
                                            fill="#cbd5e0"
                                            d="M256 64C150 64 64 150 64 256s86 192 192 192c70.1 0 131.3-37.5 164.9-93.6l.1 .1c-6.9 14.9-1.5 32.8 13 41.2c15.3 8.9 34.9 3.6 43.7-11.7c.2-.3 .4-.6 .5-.9l0 0C434.1 460.1 351.1 512 256 512C114.6 512 0 397.4 0 256S114.6 0 256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32z"
                                        />
                                    </svg>
                                </span>
                                <span v-else>TOP UP</span>
                            </a>
                        </div>
                        <div
                            class="border rounded md:flex-1 flex flex-col w-full md:w-auto p-3 my-2"
                        >
                            <div class="flex-grow">
                                <h1 class="font-bold text-lg mb-5">TELEGRAM</h1>
                                <div class="flex justify-center">
                                    <img
                                        src="img/logo/carie_telegram_qr.jpg"
                                        style="height: 105px"
                                    />
                                </div>
                                <p class="mt-10">FIND ME ON TELEGRAM</p>
                            </div>
                            <a
                                class="block mt-5 bg-gray-500 text-center hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                            >
                                <span v-if="loadingPayment">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        class="w-10 h-10 animate-spin mx-auto"
                                    >
                                        <path
                                            fill="rgba(31, 41, 55, 1)"
                                            d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z"
                                        />
                                        <path
                                            fill="#cbd5e0"
                                            d="M256 64C150 64 64 150 64 256s86 192 192 192c70.1 0 131.3-37.5 164.9-93.6l.1 .1c-6.9 14.9-1.5 32.8 13 41.2c15.3 8.9 34.9 3.6 43.7-11.7c.2-.3 .4-.6 .5-.9l0 0C434.1 460.1 351.1 512 256 512C114.6 512 0 397.4 0 256S114.6 0 256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32z"
                                        />
                                    </svg>
                                </span>
                                <span v-else>TOP UP</span>
                            </a>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="container mx-auto mt-5">
            <div class="border rounded-lg px-5 py-10 bg-white">
                <div class="text-center">
                    <h1
                        class="text-md md:text-4xl font-bold tracking-tight text-gray-700 md:text-1xl"
                    >
                        <span class="block xl:inline uppercase">
                            Acknowledgement
                        </span>
                    </h1>
                </div>
                <p
                    class="mx-auto text-sm md:text-md text-gray-400 mt-5 md:max-w-xl text-center"
                >
                    All The Transaction Are Encrypted And We Will Protect Your
                    Information At All Cost. We Will Not Capture Nor Record Your
                    Payment Information To Ensure Safe And Secure Transaction
                    Between You And Us. All The Payment Are One-Time Payment.
                </p>
                <strong
                    class="flex mx-auto text-sm justify-center md:text-md text-black mt-5 md:max-w-xl"
                >
                    Your Safety Is Our Number One Priority!
                </strong>
            </div>
        </div>
    </div>
</template>
<script setup>
import { RouterLink } from "vue-router";
import { useAuthStore, useMainStore } from "@/store/index.js";
import { reactive, ref } from "vue";
import { Switch } from "@headlessui/vue";

const main = useMainStore();
const auth = useAuthStore();
main.loadPackage();

let form = reactive({
    package_id: null,
    type: null,
    price: null,
    coin: 0,
    renew: false,
    usdt: 0,
    usdt_tax: 0,
    usdt_total: 0,
    myr: 0,
    myr_tax: 0,
    myr_total: 0,
});

const resetForm = () => {
    form.type = null;
    form.price = null;
    form.usdt = 0;
    form.usdt_tax = 0;
    form.usdt_total = 0;
    form.myr = 0;
    form.myr_tax = 0;
    form.myr_total = 0;
};
const selectPackage = async (data) => {
    if (auth.user.package !== null && !form.renew && form.coin < 1) {
        main.addMessage({
            message:
                "Please select the package you want to renew/upgrade or enter the amount of xCoin you want to top up.",
        });
        return;
    }
    if (
        (form.renew && auth.user.package !== null) ||
        auth.user.package === null
    ) {
        form.package_id = data.id;
        form.usdt = data.usdt.subtotal;
        form.usdt_tax = data.usdt.tax;
        form.usdt_total = data.usdt.total;
        form.myr = data.myr.subtotal;
        form.myr_tax = data.myr.tax;
        form.myr_total = data.myr.total;
    } else {
        form.package_id = null;
        form.usdt = 0;
        form.usdt_tax = 0;
        form.usdt_total = 0;
        form.myr = 0;
        form.myr_tax = 0;
        form.myr_total = 0;
    }
    if (auth.user.package !== null) {
        main.setLoading(true);
        await main
            .countTotal(form)
            .then(({ data, success }) => {
                if (success) {
                    form.usdt = data.usdt;
                    form.usdt_tax = data.usdt_tax;
                    form.usdt_total = data.usdt_total;
                    form.myr = data.myr;
                    form.myr_tax = data.myr_tax;
                    form.myr_total = data.myr_total;
                } else {
                    form.package_id = null;
                    form.usdt = 0;
                    form.myr = 0;
                    main.addMessage({
                        message:
                            "Renew / Upgrade System not ready yet, Please contact customer service for more info.",
                    });
                }
            })
            .finally(() => {
                main.setLoading(false);
            });
    }
};

const loadingPayment = ref(false);
const payNow = (type) => {
    if (loadingPayment.value) {
        main.addMessage({
            message: "Please wait, your payment request is on progress.",
        });
        return;
    }
    loadingPayment.value = true;
    main.createOrder({ package_id: form.package_id, coin: form.coin }).then(
        (order) => {
            if (order.success) {
                form.type = type;
                main.createPayment(order.data.id, { type })
                    .then((res) => {
                        if (res.success && res.data) {
                            switch (type) {
                                case "fpay":
                                    if (res.data.payment_url) {
                                        main.setModal(
                                            type,
                                            res.data.payment_url
                                        );
                                    }
                                    // window.open(res.data.payment_url, "_blank");
                                    break;
                                case "crypto":
                                    if (res.data.address) {
                                        main.setModal(
                                            type,
                                            res.data.address,
                                            res.data.usdt
                                        );
                                    }
                                    break;
                            }
                        }
                        loadingPayment.value = false;
                    })
                    .finally(() => {
                        loadingPayment.value = false;
                    });
            }
        }
    );
};
</script>
