<template>
    <div class="p-5 break-words">
        <div class="text-neutral-800 bg-gray-50 shadow rounded">
            <div
                v-if="result.total > 0"
                class="text-lg text-center font-bold py-5"
            >
                {{ result.id + 1 }}
            </div>
            <div
                v-for="(value, name) in result.data"
                :key="name"
                class="flex flex-wrap flex-col"
            >
                <div v-if="value && name !== 'purchase'">
                    <div class="px-4 w-full">
                        <div class="mb-4">
                            <label
                                class="inline-block mb-2 cursor-default capitalize font-1"
                            >
                                {{ name }}
                            </label>
                            <a
                                v-if="
                                    value.length &&
                                    name.toLowerCase().includes('link')
                                "
                                :href="value"
                                target="_blank"
                                class="px-5 text-blue-500 hover:text-blue-600"
                            >
                                {{ value.length ? value : "Link" }}
                            </a>
                            <img
                                v-else-if="
                                    value.length &&
                                    (name.toLowerCase().includes('photo') ||
                                        name.toLowerCase().includes('avatar'))
                                "
                                :src="value"
                                :alt="name"
                                class="w-full min-h-[2.5rem] h-auto"
                            />
                            <div
                                v-else
                                style="transition: none 0s ease 0s"
                                type="text"
                                class="block break-words overflow-visible py-1 px-3 m-0 w-full min-h-[2.5rem] h-auto text-base leading-normal bg-clip-padding bg-gray-200 rounded border border-gray-300 border-solid opacity-100 cursor-default text-zinc-600 focus:border-blue-300 focus:bg-white focus:text-zinc-600"
                            >
                                {{ getValue(value) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import moment from "moment";

// eslint-disable-next-line no-undef
const result = defineProps({
    data: {
        type: Object,
        required: true,
    },
    id: {
        type: Number,
        required: 0,
    },
    total: {
        type: Number,
        default: 0,
    },
});
const getValue = (value) => {
    if (value.length) {
        switch (true) {
            case moment(value, "YYYY-MM-DDThh:mm:ssTZD", true).isValid():
                return moment(value, "YYYY-MM-DDThh:mm:ssTZD", true).format(
                    "DD-MM-YYYY"
                );
            case moment(value, "YYYY-MM-DD hh:mm:ss", true).isValid():
                return moment(value, "YYYY-MM-DD hh:mm:ss", true).format(
                    "DD-MM-YYYY"
                );
            case moment(value, "YYYY-MM-DD", true).isValid():
                return moment(value, "YYYY-MM-DD", true).format("DD-MM-YYYY");
            // case moment(value).isValid():
            //     return moment(value).format("DD-MM-YYYY");
            default:
                return `${value}`;
        }
    }
    return "-";
};
</script>
<style scoped></style>
