<script setup></script>
<template>
    <div>
        <div>
            <div class="bg-gray-500">
                <div class="content content-full text-center text-white py-5">
                    <div class="my-3">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                            fill="currentColor"
                            class="w-16 h-16 mx-auto"
                        >
                            <path
                                d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H392.6c-5.4-9.4-8.6-20.3-8.6-32V352c0-2.1 .1-4.2 .3-6.3c-31-26-71-41.7-114.6-41.7H178.3zM528 240c17.7 0 32 14.3 32 32v48H496V272c0-17.7 14.3-32 32-32zm-80 32v48c-17.7 0-32 14.3-32 32V480c0 17.7 14.3 32 32 32H608c17.7 0 32-14.3 32-32V352c0-17.7-14.3-32-32-32V272c0-44.2-35.8-80-80-80s-80 35.8-80 80z"
                            />
                        </svg>
                    </div>
                    <h1 class="mb-0">Personal Data Protection Notice</h1>
                </div>
            </div>
        </div>
        <div class="p-5">
            <div class="rounded bg-white px-5 md:w-1/2 mx-auto font-bold p-3">
                <div>
                    <p class="text-gray-500 py-1">
                        The Personal Data Protection Act 2010 (the "Act"), which
                        regulates the processing of personal data in commercial
                        transactions, applies to xSearch.Pro . For the purpose
                        of this written notice ("Privacy Notice"), the terms
                        "personal data" and "processing" shall have the same
                        meaning as prescribed in the Act. This Privacy Notice is
                        to help you better understand the manner in which your
                        personal data is managed and to give you the options
                        available to you to access or limit the use of the
                        information provided.
                    </p>
                    <h4>Categories of personal data that we collect</h4>
                    <p class="text-gray-500 py-1">
                        The personal data collected and processed by us may
                        include:
                    </p>
                    <ol>
                        <li class="py-1">
                            Name, Identity Card number and date of birth
                        </li>
                        <li class="py-1">
                            Contact details including address, phone number(s)
                            and email address
                        </li>
                        <li class="py-1">Occupation and marital status</li>
                        <li class="py-1">
                            Any other personal data required for the purposes as
                            set out below
                        </li>
                    </ol>
                    <h4>Purpose of collecting your data</h4>
                    <p class="text-gray-500">
                        The personal data previously collected from you is
                        being, and will continue to be used for purposes
                        including without limitation:
                    </p>
                    <ol>
                        <li class="py-1">to operate your Account</li>
                        <li class="py-1">
                            to facilitate access to and use of the website and
                            mobile services relating to the Account
                        </li>
                        <li class="py-1">
                            to make decisions in relation to Members, including
                            (without limitation), suspend the provision of
                            services or terminate the Membership
                        </li>
                        <li class="py-1">
                            to confirm, update, verify and enhance your records
                        </li>
                        <li class="py-1">
                            to monitor volume and spend information
                        </li>
                        <li class="py-1">
                            to conduct market research and/or statistical
                            analysis including without limitation consumer
                            behaviour
                        </li>
                        <li class="py-1">to identify and prevent fraud</li>
                        <li class="py-1">
                            for marketing purposes (subject to any objections
                            received and/or consents obtained, for which see
                            below)
                        </li>
                        <li class="py-1">to update your status as a Member</li>
                        <li class="py-1">
                            to fulfill your requests including redemption
                            requests
                        </li>
                    </ol>
                    <h4>Source of your personal data</h4>
                    <p class="text-gray-500">
                        Your personal data is being or is to be collected from
                        you, third party service providers, registration forms,
                        redemption forms, evaluation forms, your business cards,
                        social media platform such as Facebook, Instagram etc.,
                        events, roadshows, CCTV recording, email, website/mobile
                        application, any other forms through which we collect
                        information from the members.
                    </p>
                    <h4>Disclosure of your personal data</h4>
                    <p class="text-gray-500">
                        Your personal data will be kept confidential but such
                        personal data may be provided to the following parties
                        for the purposes set out above:
                    </p>
                    <ol>
                        <li class="py-1">
                            to our business Partners (including promotional or
                            short-term Partners) and/or any other company that
                            is permitted to supply products and/or services to
                            Members.
                        </li>
                        <li class="py-1">
                            any agents, service providers and/or subcontractors
                            and its affiliates;
                        </li>
                        <li class="py-1">
                            and/or any other person(s) required or permitted by
                            law or any regulatory authority.
                        </li>
                    </ol>
                    <p class="text-gray-500 pb-2">
                        All parties mentioned above are required to protect the
                        confidentiality of the personal data they receive.
                    </p>
                    <h4>
                        Transfer of Personal Data to places outside Malaysia
                    </h4>
                    <p class="text-gray-500 pb-2">
                        We may transfer your personal data to a place outside
                        Malaysia and you hereby give your consent to the
                        transfer.
                    </p>
                    <h4>Access and Correction of Personal Data</h4>
                    <p class="text-gray-500 pb-2">
                        The Act allows you the right to access and to correct
                        the personal data you have submitted. You may at any
                        time update or amend the information provided to us
                        simply by accessing 'Update Profile' on our website, or
                        contact our Member Services Centre. Subject to provision
                        of the Act, you may, upon payment of a prescribed fee,
                        make a data access request in writing to us; and we may
                        refuse to comply with a data access request or a data
                        correction request and shall, by notice in writing,
                        inform you of our refusal and the reasons of our
                        refusal.
                    </p>
                    <h4>Third Party Personal Data</h4>
                    <p class="text-gray-500 pb-2">
                        In the event that personal data of any third party is
                        supplied by you to us, you shall ensure that such third
                        party has read this Privacy Notice and consented to us
                        collecting his/her personal data for any of the purposes
                        prior to the supply of his/her personal data to us.
                    </p>
                    <p class="text-gray-500 pb-2">
                        For as long as you remain a Member in the programme your
                        information will remain with us, and we will take all
                        practicable steps to ensure the security of the data at
                        all times from any unauthorized access. xSearch.Pro will
                        endeavor not to disclose any personally identifiable
                        information to any organization unless necessary to
                        operate the programme and/or as set out in this Privacy
                        Notice.
                    </p>
                    <p class="text-gray-500 pb-2">
                        xSearch.Pro reserves the right to amend this Privacy
                        Notice from time to time. Any material changes will be
                        posted on our website with the updated Privacy Notice.
                    </p>
                    <p class="m-0 font-bold">Have a good days.</p>
                    <p class="mb-3 font-bold">xSearch.Pro team</p>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped></style>
