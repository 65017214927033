<template>
    <div
        class="flex container mx-auto md:justify-between z-10 items-center h-16 px-5"
    >
        <a class="md:hidden" href="#" @click.prevent="showMenu">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                class="w-6 h-6 m-2"
                fill="currentColor"
            >
                <path
                    d="M0 64H448v48H0V64zM0 224H448v48H0V224zM448 384v48H0V384H448z"
                />
            </svg>
        </a>
        <router-link href="#" :to="{ name: 'search' }" class="w-full md:w-auto">
            <img
                class="h-16 mx-auto md:mx-none"
                :src="require('@/assets/logo.png')"
            />
        </router-link>
        <div v-if="!auth.getToken" class="hidden md:flex gap-2 mr-3">
            <router-link
                :to="{ name: 'login' }"
                class="border border-blue-500 py-2 px-4 rounded text-blue-500 hover:bg-blue-500 hover:text-white font-bold"
            >
                LOGIN
            </router-link>
            <router-link
                :to="{ name: 'register' }"
                class="border border-blue-500 py-2 px-4 rounded text-blue-500 hover:bg-blue-500 hover:text-white font-bold transition"
            >
                REGISTER
            </router-link>
        </div>
        <div v-else class="hidden md:flex gap-2 mr-0 md:mr-3">
            <select
                v-if="main.getSearchSessions.length > 0"
                v-model="selectSession"
                class="border border-blue-500 rounded text-black px-2"
                @change="changeResult"
            >
                <option :value="null">Search Other</option>
                <option
                    v-for="session in main.getSearchSessions"
                    :key="session.request_code"
                    :value="session.request_code"
                >
                    [{{ session.status ? "DONE" : "LOADING" }}]{{
                        session.request_code
                    }}
                </option>
            </select>
            <router-link
                :to="{ name: 'payment' }"
                class="border bg-gray-400 text-white py-2 px-4 hover:bg-gray-600 hover:text-white font-bold transition"
            >
                {{ auth.getUser.xsc ?? 0 }} xCoin
            </router-link>
            <Menu as="div" class="relative inline-block text-left">
                <div>
                    <MenuButton
                        class="inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    >
                        {{ auth.getUser.username }}
                        <span
                            class="font-bold px-2"
                            :class="{
                                'text-red-600': auth.getUser.package
                                    ? main.isExpiredDate(
                                          auth.getUser.packages[0].pivot
                                              .expired_at
                                      )
                                    : true,
                                'text-green-600': auth.getUser.package
                                    ? !main.isExpiredDate(
                                          auth.getUser.packages[0].pivot
                                              .expired_at
                                      )
                                    : false,
                            }"
                        >
                            {{ getGrade() }}
                        </span>
                        <ChevronDownIcon
                            aria-hidden="true"
                            class="-mr-1 ml-2 h-6 w-5"
                        />
                    </MenuButton>
                </div>
                <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                >
                    <MenuItems
                        class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                        <div class="py-1">
                            <MenuItem v-slot="{ active }">
                                <router-link
                                    :class="[
                                        active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                        'block px-4 py-2 text-sm',
                                    ]"
                                    :to="{ name: 'dashboard' }"
                                >
                                    Dashboard
                                </router-link>
                            </MenuItem>
                            <template v-if="auth.getUser.is_admin">
                                <MenuItem v-slot="{ active }">
                                    <router-link
                                        :class="[
                                            active
                                                ? 'bg-gray-100 text-gray-900'
                                                : 'text-gray-700',
                                            'block px-4 py-2 text-sm',
                                        ]"
                                        :to="{ name: 'user' }"
                                    >
                                        Users
                                    </router-link>
                                </MenuItem>
                                <MenuItem v-slot="{ active }">
                                    <router-link
                                        :class="[
                                            active
                                                ? 'bg-gray-100 text-gray-900'
                                                : 'text-gray-700',
                                            'block px-4 py-2 text-sm',
                                        ]"
                                        :to="{ name: 'report' }"
                                    >
                                        Reports
                                    </router-link>
                                </MenuItem>
                            </template>
                            <MenuItem v-slot="{ active }">
                                <router-link
                                    :class="[
                                        active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                        'block px-4 py-2 text-sm',
                                    ]"
                                    :to="{ name: 'profile' }"
                                    href="#"
                                >
                                    Profile
                                </router-link>
                            </MenuItem>
                            <MenuItem
                                v-if="auth.getUser.is_admin"
                                v-slot="{ active }"
                            >
                                <router-link
                                    :class="[
                                        active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                        'block px-4 py-2 text-sm',
                                    ]"
                                    :to="{ name: 'blacklist' }"
                                >
                                    Blacklist
                                </router-link>
                            </MenuItem>
                            <form action="#" method="POST">
                                <MenuItem v-slot="{ active }">
                                    <button
                                        :class="[
                                            active
                                                ? 'bg-gray-100 text-gray-900'
                                                : 'text-gray-700',
                                            'block w-full px-4 py-2 text-left text-sm',
                                        ]"
                                        type="submit"
                                        @click.prevent="auth.logout"
                                    >
                                        Sign out
                                    </button>
                                </MenuItem>
                            </form>
                        </div>
                    </MenuItems>
                </transition>
            </Menu>
        </div>
    </div>
    <div class="min-h-screen bg-gray-200 md:py-10">
        <div class="block md:hidden w-1/2 mx-auto border">
            <div v-if="!auth.getToken" class="flex justify-center my-5 gap-2">
                <router-link
                    :to="{ name: 'login' }"
                    class="border border-blue-500 py-2 px-4 rounded text-blue-500 hover:bg-blue-500 hover:text-white font-bold"
                >
                    LOGIN
                </router-link>
                <router-link
                    :to="{ name: 'register' }"
                    class="border border-blue-500 py-2 px-4 rounded text-blue-500 hover:bg-blue-500 hover:text-white font-bold transition"
                >
                    REGISTER
                </router-link>
            </div>
            <div v-else class="flex justify-center my-5 gap-2">
                <router-link
                    :to="{ name: 'payment' }"
                    class="border bg-gray-400 text-white py-2 px-4 hover:bg-gray-600 hover:text-white font-bold transition"
                >
                    {{ auth.getUser.xsc ?? 0 }} xCoin
                </router-link>
                <Menu as="div" class="relative inline-block text-left">
                    <div>
                        <MenuButton
                            class="inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                        >
                            {{ auth.getUser.username }}
                            <ChevronDownIcon
                                aria-hidden="true"
                                class="-mr-1 ml-2 h-6 w-5"
                            />
                        </MenuButton>
                    </div>
                    <transition
                        enter-active-class="transition ease-out duration-100"
                        enter-from-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95"
                    >
                        <MenuItems
                            class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                            <div class="py-1">
                                <MenuItem v-slot="{ active }">
                                    <router-link
                                        class="text-white block px-4 py-2 text-sm"
                                        :class="{
                                            'bg-white text-blue-500': active,
                                        }"
                                        :to="{ name: 'dashboard' }"
                                    >
                                        Dashboard
                                    </router-link>
                                </MenuItem>
                                <MenuItem v-slot="{ active }">
                                    <router-link
                                        class="text-white block px-4 py-2 text-sm"
                                        :class="{
                                            'bg-white text-blue-500': active,
                                        }"
                                        :to="{ name: 'profile' }"
                                        href="#"
                                    >
                                        Profile
                                    </router-link>
                                </MenuItem>
                                <form action="#" method="POST">
                                    <MenuItem v-slot="{ active }">
                                        <button
                                            class="text-white block px-4 py-2 text-sm"
                                            :class="{
                                                'bg-white text-blue-500':
                                                    active,
                                            }"
                                            type="submit"
                                            @click.prevent="auth.logout"
                                        >
                                            Sign out
                                        </button>
                                    </MenuItem>
                                </form>
                            </div>
                        </MenuItems>
                    </transition>
                </Menu>
            </div>
        </div>
        <div
            v-if="menu"
            class="fixed top-0 left-0 w-1/2 bg-blue-600 h-screen z-40 md:hidden shadow shadow-full"
        >
            <a
                href="#"
                class="p-2 bg-red-600 rounded-full fixed bottom-0 right-5 bottom-5 flex"
                @click.prevent="showMenu(event, false)"
            >
                <span class="text-white font-semibold">Close Menu</span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    class="w-6 h-6 text-white"
                    fill="currentColor"
                >
                    <path
                        d="M345 137l17-17L328 86.1l-17 17-119 119L73 103l-17-17L22.1 120l17 17 119 119L39 375l-17 17L56 425.9l17-17 119-119L311 409l17 17L361.9 392l-17-17-119-119L345 137z"
                    />
                </svg>
            </a>
            <div>
                <div
                    v-if="!auth.getToken"
                    class="flex flex-col gap-2 mr-3 pt-5"
                >
                    <router-link
                        :to="{ name: 'login' }"
                        class="py-2 px-4 rounded text-white flex font-bold"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6 mr-5"
                            fill="currentColor"
                        >
                            <path
                                d="M192 365.8L302 256 192 146.2l0 53.8c0 13.3-10.7 24-24 24L48 224l0 64 120 0c13.3 0 24 10.7 24 24l0 53.8zM352 256c0 11.5-4.6 22.5-12.7 30.6L223.2 402.4c-8.7 8.7-20.5 13.6-32.8 13.6c-25.6 0-46.4-20.8-46.4-46.4l0-33.6-96 0c-26.5 0-48-21.5-48-48l0-64c0-26.5 21.5-48 48-48l96 0 0-33.6c0-25.6 20.8-46.4 46.4-46.4c12.3 0 24.1 4.9 32.8 13.6L339.3 225.4c8.1 8.1 12.7 19.1 12.7 30.6zm-8 176l80 0c22.1 0 40-17.9 40-40l0-272c0-22.1-17.9-40-40-40l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l80 0c48.6 0 88 39.4 88 88l0 272c0 48.6-39.4 88-88 88l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24z"
                            />
                        </svg>
                        <span class="font-bold">Login</span>
                    </router-link>
                    <router-link
                        :to="{ name: 'register' }"
                        class="py-2 px-4 rounded text-white flex font-bold"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                            class="w-6 h-6 mr-5"
                            fill="currentColor"
                        >
                            <path
                                d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 256h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zm256-32H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
                            />
                        </svg>
                        <span class="font-bold">Register</span>
                    </router-link>
                </div>
                <div v-else class="flex flex-col gap-2 mr-0 md:mr-3 pt-5">
                    <router-link
                        :to="{ name: 'payment' }"
                        class="py-2 px-4 rounded text-white flex font-bold"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="w-6 h-6 mr-5"
                            fill="currentColor"
                        >
                            <path
                                d="M256 336c68.5 0 129.7-15.6 173.2-40.1c44-24.8 66.8-56.5 66.8-87.9s-22.8-63.1-66.8-87.9C385.7 95.6 324.5 80 256 80s-129.7 15.6-173.2 40.1C38.8 144.9 16 176.6 16 208s22.8 63.1 66.8 87.9C126.3 320.4 187.5 336 256 336zM0 208C0 128.5 114.6 64 256 64s256 64.5 256 144l0 16 0 96c0 70.7-114.6 128-256 128S0 390.7 0 320l0-96 0-16zM168 343.3l0 80.7c24.8 4.7 51.8 7.5 80 8l0-80c-28-.5-54.9-3.5-80-8.7zm-16-3.6c-23.4-5.9-44.9-13.6-64-23L88 399c18.6 8.7 40.2 16 64 21.6l0-81zM16 258.2L16 320c0 24 18.2 49.5 56 70.8l0-82.7C47.7 294 28.4 277 16 258.2zM344 424l0-80.7c-25.1 5.2-52 8.2-80 8.7l0 80c28.2-.4 55.2-3.2 80-8zm16-84.3l0 81c23.8-5.5 45.4-12.8 64-21.6l0-82.4c-19.1 9.3-40.6 17.1-64 23zM496 320l0-61.8c-12.4 18.8-31.7 35.7-56 49.9l0 82.7c37.8-21.3 56-46.8 56-70.8zM256 128c-49.9 0-94.8 9.3-126.8 23.9C96.5 166.8 80 185.9 80 204s16.5 37.2 49.2 52.1c32 14.6 76.9 23.9 126.8 23.9s94.8-9.3 126.8-23.9c32.7-14.9 49.2-34 49.2-52.1s-16.5-37.2-49.2-52.1C350.8 137.3 305.9 128 256 128zm192 76c0 28.2-24.7 51.2-58.6 66.7C354.9 286.5 307.7 296 256 296s-98.9-9.5-133.4-25.3C88.7 255.2 64 232.2 64 204s24.7-51.2 58.6-66.7C157.1 121.5 204.3 112 256 112s98.9 9.5 133.4 25.3C423.3 152.8 448 175.8 448 204z"
                            />
                        </svg>
                        <div>xCoin</div>
                    </router-link>
                    <Menu as="div" class="relative inline-block text-left">
                        <div>
                            <MenuButton
                                class="flex justify-between my-2 text-white w-full px-4 py-2"
                            >
                                <span class="font-bold inline-flex">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512"
                                        class="w-6 h-6 mr-5"
                                        fill="currentColor"
                                    >
                                        <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                        <path
                                            d="M320 128a96 96 0 1 0 -192 0 96 96 0 1 0 192 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM32 480H416c-1.2-79.7-66.2-144-146.3-144H178.3c-80 0-145 64.3-146.3 144zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"
                                        />
                                    </svg>
                                    {{ auth.getUser.username }}
                                </span>
                                <ChevronDownIcon
                                    aria-hidden="true"
                                    class="h-6 w-5"
                                />
                            </MenuButton>
                        </div>
                        <transition
                            enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95"
                        >
                            <MenuItems
                                class="absolute right-0 pl-2 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                                <div class="py-1">
                                    <MenuItem v-slot="{ active }">
                                        <router-link
                                            :class="[
                                                active
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'text-gray-700',
                                                'block px-4 py-2 text-sm',
                                            ]"
                                            :to="{ name: 'dashboard' }"
                                        >
                                            Dashboard
                                        </router-link>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                        <router-link
                                            :class="[
                                                active
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'text-gray-700',
                                                'block px-4 py-2 text-sm',
                                            ]"
                                            :to="{ name: 'profile' }"
                                            href="#"
                                        >
                                            Profile
                                        </router-link>
                                    </MenuItem>
                                    <form action="#" method="POST">
                                        <MenuItem v-slot="{ active }">
                                            <button
                                                :class="[
                                                    active
                                                        ? 'bg-gray-100 text-gray-900'
                                                        : 'text-gray-700',
                                                    'block w-full px-4 py-2 text-left text-sm',
                                                ]"
                                                type="submit"
                                                @click.prevent="auth.logout"
                                            >
                                                Sign out
                                            </button>
                                        </MenuItem>
                                    </form>
                                </div>
                            </MenuItems>
                        </transition>
                    </Menu>
                </div>
            </div>
        </div>
        <slot />
    </div>
</template>
<script setup>
import { RouterLink, useRouter } from "vue-router";
import { MenuButton, MenuItem, MenuItems, Menu } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { useAuthStore, useMainStore } from "@/store/index.js";
import { ref } from "vue";

const main = useMainStore();
const auth = useAuthStore();
const router = useRouter();
if (auth.getToken) {
    auth.me();
}
let selectSession = ref(null);
main.$subscribe((mutation, state) => {
    if (state.session !== selectSession.value) {
        selectSession.value =
            state.session === null ? null : state.session.request_code;
    }
});
const changeResult = () => {
    main.setSession(selectSession.value);
    if (selectSession.value === null) {
        router.push({
            name: "search",
        });
        return;
    }
    router.push({
        name: "search_result",
        params: { request_code: selectSession.value },
    });
};
let menu = ref(false);
const getGrade = () => {
    return auth.getUser.package ? "[" + auth.getUser.package.name + "]" : "";
};
const showMenu = (event, open = true) => {
    menu.value = open;
};
</script>
<style scoped></style>
