<template>
    <div class="container mx-auto px-5">
        <div class="border rounded p-5 bg-white">
            <div class="md:flex justify-between">
                <div class="flex md:flex-col justify-center md:justify-start">
                    <h1 class="text-md font-bold">Result For :</h1>
                    <div
                        v-if="props.search.birth_year"
                        class="ml-3 md:ml-0 text-md"
                    >
                        Year: {{ props.search.birth_year }}
                    </div>
                    <div v-if="props.search.state" class="ml-3 md:ml-0 text-md">
                        State: {{ props.search.state }}
                    </div>
                    <div v-if="props.search.name" class="ml-3 md:ml-0 text-md">
                        Name: {{ props.search.name }}
                    </div>
                    <div v-if="props.search.ic" class="ml-3 md:ml-0 text-md">
                        IC: {{ props.search.ic }}
                    </div>
                </div>
                <div
                    class="flex mt-3 flex-1 md:mt-0 gap-2 items-center justify-center md:justify-end"
                >
                    <a
                        href="#"
                        @click.prevent="back"
                        class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Back to Home
                    </a>
                </div>
            </div>
        </div>
        <div class="border rounded mt-5 p-5 bg-white">
            <div class="flex flex-col">
                <div
                    class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 w-full"
                >
                    <div
                        class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
                    >
                        <div
                            class="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                        >
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th
                                            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                            scope="col"
                                        >
                                            Name
                                        </th>
                                        <th
                                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            IC Number
                                        </th>
                                        <th
                                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            State
                                        </th>
                                        <th
                                            class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                                            scope="col"
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white">
                                    <tr v-for="(user, key) in data" :key="key">
                                        <td
                                            class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                                        >
                                            {{ user.NAME }}
                                        </td>
                                        <td
                                            class="whitespace-nowrap px-2 py-4 text-sm text-gray-500"
                                        >
                                            {{ user.IC_NUMBER }}
                                        </td>
                                        <td
                                            class="whitespace-nowrap px-2 py-4 text-sm text-gray-500"
                                        >
                                            {{ user.STATE }}
                                        </td>
                                        <td
                                            class="relative whitespace-nowrap px-4 py-4 text-center text-sm font-medium sm:pr-6 text-indigo-600 hover:text-indigo-900"
                                        >
                                            <a
                                                v-if="!user.PURCHASE_DETAIL"
                                                @click.prevent="
                                                    purchase(
                                                        user.MYID,
                                                        'detail'
                                                    )
                                                "
                                                href="#"
                                                class="md:w-36 mt-3 md:mt-0 items-center justify-center bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
                                            >
                                                Purchase Details
                                            </a>
                                            <a
                                                v-else
                                                @click.prevent="
                                                    showDetail(user)
                                                "
                                                href="#"
                                                class="md:w-36 mt-3 md:mt-0 items-center justify-center bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
                                            >
                                                View Details
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useMainStore } from "@/store/index.js";

// eslint-disable-next-line no-undef
const emit = defineEmits(["back", "purchase", "showDetail"]);
const back = () => emit("back", true);
const purchase = (myid, type = "detail") => {
    main.createOrder({
        type: type,
        myid: myid,
    }).then((res) => {
        if (res.success) {
            emit("purchase", true);
        }
    });
};

// eslint-disable-next-line no-undef
const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
    search: {
        type: Object,
        required: true,
    },
});

const showDetail = (user) => emit("showDetail", user);

const main = useMainStore();
</script>
<style scoped></style>
