<template>
    <component :is="main.getLayout">
        <div
            aria-live="assertive"
            class="fixed z-50 inset-0 flex items-end px-4 py-6 pointer-events-none md:p-6 sm:items-start"
        >
            <div
                class="w-full flex flex-col items-center space-y-4 sm:items-end"
            >
                <template v-for="(message, key) in getMessages" :key="key">
                    <notification :data="message" :index="key" />
                </template>
            </div>
        </div>
        <template v-for="(modal, key) in modals" :key="key">
            <modal :modal="modal" :id="key" />
        </template>
        <router-view :key="$route.fullPath" />
    </component>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useAuthStore, useMainStore } from "@/store";
import Notification from "@/components/Notification.vue";
import Modal from "@/components/Modal.vue";
import { inject, nextTick, onBeforeUnmount, reactive } from "vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { useRouter } from "vue-router";
import { useReCaptcha } from "vue-recaptcha-v3";

const main = useMainStore();
const auth = useAuthStore();
const emitter = inject("emitter");
const router = useRouter();
const { getMessages, modals } = storeToRefs(main);
const { recaptchaLoaded, executeRecaptcha } = useReCaptcha();
main.connectSocket();
// eslint-disable-next-line no-undef
Socket.connector.pusher.connection.bind("state_change", ({ current }) => {
    if (current === "connecting" || current === "unavailable") {
        const stats = current[0].toUpperCase() + current.slice(1);
        main.addMessage({
            message: "Websocket " + stats + ".",
            success: false,
        });
        main.setSocket();
        main.resetChannels();
    }
});
// eslint-disable-next-line no-undef
Socket.connector.pusher.connection.bind("connected", (event) => {
    if (auth.getToken) {
        main.setSocket(event);
        // eslint-disable-next-line no-undef
        main.subscribeChannel(event);
    }
});

// eslint-disable-next-line no-undef
Socket.connector.pusher.connection.bind("disconnected", () => {
    nextTick(() => {
        main.addMessage({
            message: "Websocket Disconnected.",
            success: false,
        });
        main.setSocket();
        main.resetChannels();
    });
});
auth.$subscribe((mutation, state) => {
    if (state.token && !main.isSocketConnected) {
        // eslint-disable-next-line no-undef
        Socket.connector.pusher.connect();
    }

    if (!state.token && main.isSocketConnected) {
        // eslint-disable-next-line no-undef
        Socket.disconnect();
    }
});

emitter.on("search", async (sensitive = false) => {
    const form = reactive(main.getSearch);
    if (!main.isLoading && !main.startSearch) {
        const request_code = new Date().getTime();
        main.addMessage({
            message: "Loading...",
            success: true,
        });
        main.syncSearchSession(request_code, form);
        main.setSession(request_code);
        await router.push({
            name: "search_result",
            params: { request_code },
        });
        await recaptchaLoaded();
        // Execute reCAPTCHA with action "login".
        form.token = await executeRecaptcha("search");
        form.sensitive = sensitive;
        main.loadResult(form, request_code);
        main.setBotAction(main.loadResult, [form, request_code]);
        if (sensitive) {
            delete form.no;
        }
    }
});

onBeforeUnmount(() => {
    // eslint-disable-next-line no-undef
    Socket.disconnect();
});
</script>
