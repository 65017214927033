<template>
    <div class="container mx-auto px-5">
        <div class="border rounded-lg p-5 bg-white">
            <div class="flex justify-between">
                <div
                    class="flex justify-center md:justify-start font-bold items-center"
                >
                    Profile
                </div>
            </div>
        </div>
        <div class="border rounded-lg mt-5 pb-5 bg-white">
            <main class="mx-auto max-w-7xl my-10">
                <label
                    class="block mx-auto w-full md:w-1/2 text-xl font-bold text-center border rounded shadow-sm px-3 py-2"
                >
                    API Request
                </label>
                <div class="mx-auto w-full md:w-1/2 flex flex-col gap-3 mb-5">
                    <div
                        v-if="false"
                        class="border relative mt-1 rounded-md shadow-sm px-3 py-2"
                    >
                        <label
                            class="block text-xs font-medium text-gray-400 mb-1"
                            for="webhook"
                        >
                            API Webhook
                        </label>
                        <input
                            id="webhook"
                            class="block w-full sm:text-sm outline-none"
                            name="webhook"
                            type="text"
                            v-model="webhookForm.webhook"
                        />
                    </div>
                    <div
                        class="border relative mt-1 rounded-md shadow-sm px-3 py-2"
                    >
                        <label
                            class="block text-xs font-medium text-gray-400 mb-1"
                            for="webhook"
                        >
                            API Whitelist IP (exp: 202.202.202.202)
                        </label>
                        <input
                            id="webhook"
                            class="block w-full sm:text-sm outline-none"
                            name="webhook"
                            type="text"
                            v-model="webhookForm.whitelist"
                        />
                    </div>
                    <div
                        class="border relative mt-1 rounded-md shadow-sm px-3 py-2 bg-gray-100"
                    >
                        <label
                            class="block text-xs font-medium text-gray-400 mb-1"
                            for="webhook"
                        >
                            API Token
                        </label>
                        <input
                            id="webhook"
                            class="block w-full sm:text-sm outline-none"
                            name="webhook"
                            type="text"
                            placeholder="API Token Will Generate After Update API Whitelist Domain."
                            readonly
                            disabled
                            :value="auth.getUser.api_token"
                        />
                    </div>
                    <div class="flex gap-2">
                        <div class="w-1/3">
                            <button
                                @click="removeAPI"
                                class="w-full rounded-md border border-gray-600 bg-gray-400 px-4 py-2 text-sm font-medium text-white hover:bg-gray-500 hover:text-white"
                                type="button"
                            >
                                Delete
                            </button>
                        </div>
                        <div class="w-full">
                            <button
                                class="w-full rounded-md border border-blue-500 bg-white px-4 py-2 text-sm font-medium text-blue-500 hover:bg-blue-500 hover:text-white"
                                type="button"
                                @click="applyAPI"
                            >
                                UPDATE
                            </button>
                        </div>
                    </div>
                </div>
                <label
                    class="block mx-auto w-full md:w-1/2 text-xl font-bold text-center border rounded shadow-sm px-3 py-2"
                >
                    Update Profile
                </label>
                <div class="mx-auto w-full md:w-1/2 flex flex-col gap-3">
                    <div
                        class="border relative mt-1 rounded-md shadow-sm px-3 py-2 bg-gray-100"
                    >
                        <label
                            class="block text-xs font-medium text-gray-400 mb-1"
                            for="xsc"
                        >
                            xSearch Credit (xCoin)
                        </label>
                        <input
                            id="xsc"
                            :value="auth.getUser.xsc"
                            class="block w-full sm:text-sm outline-none"
                            name="xscs"
                            readonly
                            disabled
                            type="text"
                        />
                    </div>
                    <div
                        class="border relative mt-1 rounded-md shadow-sm px-3 py-2"
                    >
                        <label
                            class="block text-xs font-medium text-gray-400 mb-1"
                            for="username"
                            >Username</label
                        >
                        <input
                            id="username"
                            :value="auth.getUser.username"
                            class="block w-full sm:text-sm outline-none"
                            name="username"
                            readonly
                            type="text"
                        />
                    </div>
                    <div
                        class="border relative mt-1 rounded-md shadow-sm px-3 py-2"
                    >
                        <label
                            class="block text-xs font-medium text-gray-400 mb-1"
                            for="email"
                            >Email</label
                        >
                        <input
                            id="email"
                            :value="auth.getUser.email"
                            class="block w-full sm:text-sm outline-none"
                            name="email"
                            readonly
                            type="email"
                        />
                    </div>
                    <div
                        class="border relative mt-1 rounded-md shadow-sm px-3 py-2"
                    >
                        <label
                            class="block text-xs font-medium text-gray-400 mb-1"
                            for="password"
                            >Password</label
                        >
                        <input
                            id="password"
                            v-model="form.password"
                            class="block w-full sm:text-sm outline-none"
                            name="password"
                            type="password"
                        />
                    </div>
                    <div
                        class="border relative mt-1 rounded-md shadow-sm px-3 py-2"
                    >
                        <label
                            class="block text-xs font-medium text-gray-400 mb-1"
                            for="confirm_password"
                            >Confirm Password</label
                        >
                        <input
                            id="confirm_password"
                            v-model="form.password_confirmation"
                            class="block w-full sm:text-sm outline-none"
                            name="confirm_password"
                            type="password"
                        />
                    </div>
                    <div class="flex gap-2">
                        <div class="w-1/3">
                            <router-link
                                :to="{ name: 'home' }"
                                class="w-full rounded-md border border-gray-600 bg-gray-400 px-4 py-2 text-sm font-medium text-white hover:bg-gray-500 hover:text-white"
                                type="button"
                                >BACK
                            </router-link>
                        </div>
                        <div class="w-full">
                            <button
                                class="w-full rounded-md border border-blue-500 bg-white px-4 py-2 text-sm font-medium text-blue-500 hover:bg-blue-500 hover:text-white"
                                type="button"
                                @click="updateAction"
                            >
                                UPDATE
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>
<script setup>
import { useReCaptcha } from "vue-recaptcha-v3";
import { useAuthStore } from "@/store";
import { RouterLink } from "vue-router";
import { reactive } from "vue";

const auth = useAuthStore();
const form = reactive({
    password: null,
    password_confirmation: null,
});
const webhookForm = reactive({
    webhook: null,
    whitelist: null,
});

auth.$subscribe((mutation, state) => {
    webhookForm.webhook = state.user.webhook ?? null;
    webhookForm.whitelist = state.user.whitelist ?? null;
});

const { recaptchaLoaded, executeRecaptcha } = useReCaptcha();
const applyAPI = async () => {
    auth.applyAPI(webhookForm);
};
const removeAPI = async () => {
    auth.applyAPI({ webhook: null, whitelist: null });
};
const updateAction = async () => {
    await recaptchaLoaded();

    // Execute reCAPTCHA with action "login".
    const token = await executeRecaptcha("update");
    form.rc = "update";
    form.token = token;

    const auth = useAuthStore();
    auth.updatePassword(form);
};
</script>
