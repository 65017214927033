<template>
    <div
        v-if="props.data"
        class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
    >
        <div class="flex flex-1 justify-between sm:hidden">
            <a
                @click.prevent="changePrev"
                class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                href="#"
            >
                Previous
            </a>
            <a
                @click.prevent="changeNext"
                class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                href="#"
            >
                Next
            </a>
        </div>
        <div
            class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between"
        >
            <div>
                <p class="text-sm text-gray-700">
                    Showing
                    {{ " " }}
                    <span class="font-medium">
                        {{ props.data.from }}
                    </span>
                    {{ " " }}
                    to
                    {{ " " }}
                    <span class="font-medium">
                        {{ props.data.to }}
                    </span>
                    {{ " " }}
                    of
                    {{ " " }}
                    <span class="font-medium">
                        {{ props.data.total }}
                    </span>
                    {{ " " }}
                    results
                </p>
            </div>
            <div>
                <nav
                    aria-label="Pagination"
                    class="isolate inline-flex -space-x-px rounded-md shadow-sm"
                >
                    <template
                        v-for="(link, key) in props.data.links"
                        :key="key"
                    >
                        <a
                            @click.prevent="changePage(link, key)"
                            class="relative inline-flex items-center border px-2 py-2 text-sm font-medium focus:z-20"
                            :class="{
                                'bg-white border-gray-300 text-gray-500 hover:bg-gray-50':
                                    !link.active,
                                'z-10 bg-indigo-50 border-indigo-500 text-indigo-600':
                                    link.active,
                                'rounded-r-md': key !== 0 && link.url === null,
                                'rounded-l-md': key === 0 && link.url === null,
                            }"
                            href="#"
                        >
                            <span class="sr-only">{{ link.label }}</span>
                            <span v-html="link.label"></span>
                        </a>
                    </template>
                </nav>
            </div>
        </div>
    </div>
</template>
<script setup>
// eslint-disable-next-line no-undef
const props = defineProps({
    data: {
        type: Object,
        default: null,
    },
});
// eslint-disable-next-line no-undef
const emit = defineEmits(["change"]);

const changePrev = () => {
    const page =
        parseInt(props.data.links.filter((link) => link.active)[0].label) - 1;
    emit("change", page <= 1 ? 1 : page);
};
const changeNext = () => {
    const page =
        parseInt(props.data.links.filter((link) => link.active)[0].label) + 1;
    emit("change", page >= props.data.last_page ? props.data.last_page : page);
};
const changePage = (link, key) => {
    if (link.url) {
        switch (true) {
            case link.label.includes("Next"):
                changeNext();
                break;
            case link.label.includes("Previous"):
                changePrev();
                break;
            default:
                emit("change", link.label);
                break;
        }
        // emit("change", link.label);
    } else {
        key === 0 ? changePrev() : changeNext();
    }
};
</script>
