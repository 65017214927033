import { defineStore } from "pinia";
import axios from "@/axios.js";
import router from "@/router";

export const useAdminStore = defineStore({
    id: "admin",
    state: () => ({
        loading: false,
        messages: [],
        users: [],
        orders: [],
        reports: [],
        packages: [],
        blacklists: [],
    }),
    getters: {
        isLoading: (state) => state.loading,
        getMessages: (state) => state.messages,
        getUsers: (state) => state.users,
        getReports: (state) => state.reports,
        getPackages: (state) => state.packages,
        getOrders: (state) => state.orders,
        getBlacklists: (state) => state.blacklists,
    },
    actions: {
        loadPackage(form = null) {
            return axios.get("admin/package", form).then(({ data }) => {
                this.packages = data;
            });
        },
        showPackage(id = null) {
            return axios.get("admin/package/" + id);
        },
        createPackage(form = null) {
            return axios.post("admin/package", form).then(() => {
                this.loadPackage();
                router
                    .isReady()
                    .then(() => router.replace({ name: "admin_package" }));
            });
        },
        updatePackage(form = null) {
            return axios.patch("admin/package/" + form.id, form).then(() => {
                this.loadPackage();
                router
                    .isReady()
                    .then(() => router.replace({ name: "admin_package" }));
            });
        },
        deletePackage(form = null) {
            return axios.delete("admin/package/" + form.id).then(() => {
                this.loadPackage();
            });
        },
        loadOrder(form = null) {
            return axios.get("admin/order", form).then(({ data }) => {
                this.orders = data;
            });
        },
        loadBlacklist(form = null) {
            return axios
                .get("admin/blacklist", {
                    params: form,
                })
                .then(({ data }) => {
                    this.blacklists = data;
                });
        },
        addBlacklist(form = null) {
            return axios.post("admin/blacklist", form).then(() => {
                this.loadBlacklist();
            });
        },
        deleteBlacklist(id) {
            return axios.delete("admin/blacklist/" + id).then(() => {
                this.loadBlacklist();
            });
        },
        showOrder(id = null) {
            return axios.get("admin/order/" + id);
        },
        createOrder(form = null) {
            return axios.post("admin/order", form).then(() => {
                this.loadOrder();
                router
                    .isReady()
                    .then(() => router.replace({ name: "admin_order" }));
            });
        },
        updateOrder(form = null) {
            return axios.patch("admin/order/" + form.id, form).then(() => {
                this.loadOrder();
                router
                    .isReady()
                    .then(() => router.replace({ name: "admin_order" }));
            });
        },
        deleteOrder(form = null) {
            return axios.delete("admin/order/" + form.id).then(() => {
                this.loadOrder();
            });
        },

        loadUser(form = null) {
            return axios
                .get("admin/user", {
                    params: form,
                })
                .then(({ data }) => {
                    this.users = data;
                });
        },
        showUser(id = null) {
            return axios.get("admin/user/" + id);
        },
        createUser(form = null) {
            return axios.post("admin/user", form).then(() => {
                this.loadUser();
                router
                    .isReady()
                    .then(() => router.replace({ name: "admin_user" }));
            });
        },
        updateUser(form = null) {
            return axios.patch("admin/user/" + form.id, form).then(() => {
                this.loadUser();
                router
                    .isReady()
                    .then(() => router.replace({ name: "admin_user" }));
            });
        },
        deleteUser(form = null) {
            return axios.delete("admin/user/" + form.id).then(() => {
                this.loadUser();
            });
        },
        renewUser(form = null) {
            return axios.delete("admin/user/renew/" + form.id).then(() => {
                this.loadUser();
            });
        },
        loadReport(form = null) {
            return axios
                .get("admin/report", {
                    params: form,
                })
                .then(({ data }) => {
                    this.reports = data;
                });
        },
    },
});
