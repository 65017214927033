<template>
    <div class="flex-auto p-5 pb-2 break-words bg-gray-50 shadow rounded m-2">
        <div v-if="result.total > 0" class="text-lg text-center font-bold py-5">
            {{ result.id + 1 }}
        </div>
        <div class="flex flex-wrap md:flex-auto -mx-4 text-neutral-800">
            <div
                class="order-2 px-4 md:w-4/5 md:order-1"
                style="min-height: 1px"
            >
                <div class="flex flex-wrap -mx-4">
                    <div
                        class="relative px-4 w-full md:flex-shrink-0 md:flex-grow-0 md:w-1/2"
                        style="min-height: 1px"
                    >
                        <div class="mb-4">
                            <label
                                class="inline-block mb-2 cursor-default font-bold"
                                for="NAME"
                            >
                                Full Name
                            </label>
                            <div
                                id="NAME"
                                style="transition: none 0s ease 0s"
                                type="text"
                                class="block overflow-visible py-1 px-3 m-0 w-full min-h-[2.5rem] h-auto text-base leading-normal bg-clip-padding bg-gray-200 rounded border border-gray-300 border-solid opacity-100 cursor-default text-zinc-600 focus:border-blue-300 focus:bg-white focus:text-zinc-600"
                            >
                                {{ result.data.NAME }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="relative px-4 w-full md:flex-shrink-0 md:flex-grow-0 md:w-1/2"
                        style="min-height: 1px"
                    >
                        <div class="mb-4">
                            <label
                                class="inline-block mb-2 cursor-default font-bold"
                                for="GENDER"
                            >
                                Gender
                            </label>
                            <div
                                id="GENDER"
                                style="transition: none 0s ease 0s"
                                type="text"
                                class="block overflow-visible py-1 px-3 m-0 w-full min-h-[2.5rem] h-auto text-base leading-normal bg-clip-padding bg-gray-200 rounded border border-gray-300 border-solid opacity-100 cursor-default text-zinc-600 focus:border-blue-300 focus:bg-white focus:text-zinc-600"
                            >
                                {{
                                    result.data.GENDER === "P"
                                        ? "PEREMPUAN"
                                        : "LELAKI"
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap -mx-4">
                    <div
                        class="relative px-4 w-full md:flex-shrink-0 md:flex-grow-0 md:w-1/2"
                        style="min-height: 1px"
                    >
                        <div class="mb-4">
                            <label
                                class="inline-block mb-2 cursor-default font-bold"
                                for="IC_NUMBER"
                            >
                                NEW I/C Number
                            </label>
                            <div
                                id="IC_NUMBER"
                                style="transition: none 0s ease 0s"
                                type="text"
                                class="block overflow-visible py-1 px-3 m-0 w-full min-h-[2.5rem] h-auto text-base leading-normal bg-clip-padding bg-gray-200 rounded border border-gray-300 border-solid opacity-100 cursor-default text-zinc-600 focus:border-blue-300 focus:bg-white focus:text-zinc-600"
                            >
                                {{ result.data.IC_NUMBER }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="relative px-4 w-full md:flex-shrink-0 md:flex-grow-0 md:w-1/2"
                        style="min-height: 1px"
                    >
                        <div class="mb-4">
                            <label
                                class="inline-block mb-2 cursor-default font-bold"
                                for="IC_NUMBER"
                            >
                                OLD I/C Number
                            </label>
                            <div
                                id="IC_NUMBER"
                                style="transition: none 0s ease 0s"
                                type="text"
                                class="block overflow-visible py-1 px-3 m-0 w-full min-h-[2.5rem] h-auto text-base leading-normal bg-clip-padding bg-gray-200 rounded border border-gray-300 border-solid opacity-100 cursor-default text-zinc-600 focus:border-blue-300 focus:bg-white focus:text-zinc-600"
                            >
                                {{ result.data.OLD_IC_NUMBER ?? "-" }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap -mx-4">
                    <div
                        class="relative px-4 w-full md:flex-shrink-0 md:flex-grow-0 md:w-1/2"
                        style="min-height: 1px"
                    >
                        <div class="mb-4">
                            <label
                                class="inline-block mb-2 cursor-default font-bold"
                                for="RACE"
                            >
                                Race
                            </label>
                            <div
                                id="RACE"
                                style="transition: none 0s ease 0s"
                                type="text"
                                class="block overflow-visible py-1 px-3 m-0 w-full min-h-[2.5rem] h-auto text-base leading-normal bg-clip-padding bg-gray-200 rounded border border-gray-300 border-solid opacity-100 cursor-default text-zinc-600 focus:border-blue-300 focus:bg-white focus:text-zinc-600"
                            >
                                {{ result.data.RACEDESC }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="relative px-4 w-full md:flex-shrink-0 md:flex-grow-0 md:w-1/2"
                        style="min-height: 1px"
                    >
                        <div class="mb-4">
                            <label
                                class="inline-block mb-2 cursor-default font-bold"
                                for="RACE"
                            >
                                Religion
                            </label>
                            <div
                                id="RACE"
                                style="transition: none 0s ease 0s"
                                type="text"
                                class="block overflow-visible py-1 px-3 m-0 w-full min-h-[2.5rem] h-auto text-base leading-normal bg-clip-padding bg-gray-200 rounded border border-gray-300 border-solid opacity-100 cursor-default text-zinc-600 focus:border-blue-300 focus:bg-white focus:text-zinc-600"
                            >
                                {{ result.data.RELIGIONDESC }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap -mx-4">
                    <div
                        class="relative px-4 w-full md:flex-shrink-0 md:flex-grow-0 md:w-1/2"
                        style="min-height: 1px"
                    >
                        <div class="mb-4">
                            <label
                                class="inline-block mb-2 cursor-default font-bold"
                                for="phone_number"
                            >
                                Phone Number
                            </label>
                            <div
                                id="HPHONE_NUM"
                                style="transition: none 0s ease 0s"
                                type="text"
                                class="block overflow-visible py-1 px-3 m-0 w-full min-h-[2.5rem] h-auto text-base leading-normal bg-clip-padding bg-gray-200 rounded border border-gray-300 border-solid opacity-100 cursor-default text-zinc-600 focus:border-blue-300 focus:bg-white focus:text-zinc-600"
                            >
                                {{ result.data.HPHONE_NUM ?? "-" }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="relative px-4 w-full md:flex-shrink-0 md:flex-grow-0 md:w-1/2"
                        style="min-height: 1px"
                    >
                        <div class="mb-4">
                            <label
                                class="inline-block mb-2 cursor-default font-bold"
                                for="EMAIL"
                            >
                                Email
                            </label>
                            <div
                                id="EMAIL"
                                name="EMAIL"
                                style="transition: none 0s ease 0s"
                                type="text"
                                class="block overflow-visible py-1 px-3 m-0 w-full min-h-[2.5rem] h-auto text-base leading-normal bg-clip-padding bg-gray-200 rounded border border-gray-300 border-solid opacity-100 cursor-default text-zinc-600 focus:border-blue-300 focus:bg-white focus:text-zinc-600"
                            >
                                {{ result.data.EMAIL ?? "-" }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap -mx-4">
                    <div
                        class="relative px-4 w-full md:flex-shrink-0 md:flex-grow-0 md:w-1/2"
                        style="min-height: 1px"
                    >
                        <div class="mb-4">
                            <label
                                class="inline-block mb-2 cursor-default font-bold"
                                for="death_date"
                            >
                                Death Date
                            </label>
                            <div
                                id="death_date"
                                style="transition: none 0s ease 0s"
                                type="text"
                                class="block overflow-visible py-1 px-3 m-0 w-full min-h-[2.5rem] h-auto text-base leading-normal bg-clip-padding bg-gray-200 rounded border border-gray-300 border-solid opacity-100 cursor-default text-zinc-600 focus:border-blue-300 focus:bg-white focus:text-zinc-600"
                            >
                                {{
                                    result.data.DEATHDATE
                                        ? moment(result.data.DEATHDATE).format(
                                              "YYYY-MM-DD"
                                          )
                                        : "-"
                                }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="relative px-4 w-full md:flex-shrink-0 md:flex-grow-0 md:w-1/2"
                        style="min-height: 1px"
                    >
                        <div class="mb-4">
                            <label
                                class="inline-block mb-2 cursor-default font-bold"
                                for="NAME"
                            >
                                Uniformed Service ID
                            </label>
                            <div
                                id="NAME"
                                style="transition: none 0s ease 0s"
                                type="text"
                                class="block overflow-visible py-1 px-3 m-0 w-full min-h-[2.5rem] h-auto text-base leading-normal bg-clip-padding bg-gray-200 rounded border border-gray-300 border-solid opacity-100 cursor-default text-zinc-600 focus:border-blue-300 focus:bg-white focus:text-zinc-600"
                            >
                                {{ result.data.POMI_ID ?? "-" }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap -mx-4">
                    <div
                        class="relative px-4 w-full md:flex-shrink-0 md:flex-grow-0 md:w-1/2"
                        style="min-height: 1px"
                    >
                        <div class="mb-4">
                            <label
                                class="inline-block mb-2 cursor-default font-bold"
                                for="BIRTHDATE"
                            >
                                Birthdate
                            </label>
                            <div
                                id="BIRTHDATE"
                                style="transition: none 0s ease 0s"
                                type="text"
                                class="block overflow-visible py-1 px-3 m-0 w-full min-h-[2.5rem] h-auto text-base leading-normal bg-clip-padding bg-gray-200 rounded border border-gray-300 border-solid opacity-100 cursor-default text-zinc-600 focus:border-blue-300 focus:bg-white focus:text-zinc-600"
                            >
                                {{
                                    result.data.BIRTHDATE
                                        ? moment(result.data.BIRTHDATE).format(
                                              "YYYY-MM-DD"
                                          )
                                        : "-"
                                }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="relative px-4 w-full md:flex-shrink-0 md:flex-grow-0 md:w-1/2"
                        style="min-height: 1px"
                    >
                        <div class="mb-4">
                            <label
                                class="inline-block mb-2 cursor-default font-bold"
                                for="PERMANENTADDRESS"
                            >
                                Permanent Address
                            </label>
                            <div
                                id="PERMANENTADDRESS"
                                name="PERMENENTADDRESS"
                                style="transition: none 0s ease 0s"
                                type="text"
                                class="block overflow-visible py-1 px-3 m-0 w-full min-h-[2.5rem] h-auto text-base leading-normal bg-clip-padding bg-gray-200 rounded border border-gray-300 border-solid opacity-100 cursor-default text-zinc-600 focus:border-blue-300 focus:bg-white focus:text-zinc-600"
                            >
                                {{
                                    result.data.PERMANENTADDRESSCITYDESC ?? "-"
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Image -->
            <div
                class="order-1 px-4 w-full md:w-1/5 md:order-2 md:mt-6 text-center"
                style="min-height: 1px"
            >
                <img
                    v-if="result.data.AVATAR"
                    :alt="result.data.NAME"
                    type="text"
                    :src="'data:image/png;base64,' + result.data.AVATAR"
                    class="mt-1 w-11/12 mb-5 md:mb-0 mx-auto md:w-auto md:mx-0 align-middle border border-black border-solid"
                />
                <img
                    v-else
                    src="/img/noentry.png"
                    class="mt-1 align-middle border border-black border-solid bg-white"
                    alt="X-Search"
                />
            </div>
            <!-- End of Image -->
        </div>
        <div class="flex flex-col gap-5 mt-5">
            <div class="w-full flex flex-col items-center gap-2">
                <div class="w-full font-bold break-words bg-gray-50">
                    Residential Address
                </div>
                <div
                    class="w-full min-h-[2.5rem] h-auto whitespace-pre-wrap pl-2 py-2 text-left rounded bg-clip-padding bg-gray-200 border border-gray-300 border-solid"
                >
                    {{ getAddress(result.data) }}
                </div>
                <div class="w-full text-center">
                    <button
                        class="px-3 py-2 text-white mx-auto bg-blue-500 hover:bg-blue-600 rounded"
                        @click="searchAddress(result.data)"
                    >
                        Search
                    </button>
                </div>
            </div>
            <div class="w-full flex flex-col items-center gap-2">
                <div class="w-full font-bold break-words bg-gray-50">
                    Correspondence Address
                </div>
                <div
                    class="w-full whitespace-pre-wrap pl-2 py-2 text-left rounded bg-clip-padding bg-gray-200 border border-gray-300 border-solid"
                >
                    {{ getCorrAddress(result.data) }}
                </div>
                <div class="w-full text-center">
                    <button
                        class="px-3 py-2 text-white mx-auto bg-blue-500 hover:bg-blue-600 rounded"
                        @click="searchCorrAddress(result.data)"
                    >
                        Search
                    </button>
                </div>
            </div>
        </div>
        <div class="border-t border-gray-200 mt-3 pt-3">
            <div
                id="update_date"
                style="transition: none 0s ease 0s"
                type="text"
                class="block overflow-visible pt-1 px-3 m-0 w-full min-h-[2.5rem] h-auto text-base leading-normal bg-clip-padding opacity-100 cursor-default text-zinc-600 focus:border-blue-300 focus:bg-white focus:text-zinc-600"
            >
                <label class="pr-2 cursor-default font-bold" for="update_date">
                    Updated Date
                </label>
                {{ getUpdateDate(result.data) }}
            </div>
        </div>
    </div>
</template>
<script setup>
import moment from "moment";
import { useMainStore } from "@/store/index.js";
import { inject } from "vue";
const emitter = inject("emitter");
// eslint-disable-next-line no-undef
const result = defineProps({
    data: {
        type: Object,
        required: true,
    },
    total: {
        type: Number,
        default: 0,
    },
    id: {
        type: Number,
        default: 0,
    },
});
const main = useMainStore();
const searchAddress = (data) => {
    const no = data.ADDRESS1;
    const jalan = data.ADDRESS2;
    const taman = data.ADDRESS3;
    const postcode = data.POSTCODE;

    main.updateSearch({
        no,
        jalan,
        taman,
        postcode,
        type: "address",
        sensitive: true,
    });
    emitter.emit("search", true);
};

const getAddress = (data) => {
    return (
        data.ADDRESS1 +
        "\n" +
        data.ADDRESS2 +
        "\n" +
        data.ADDRESS3 +
        "\n" +
        data.POSTCODE +
        "\n" +
        data.CITYDESC +
        "\n" +
        data.STATEDESC
    );
};

const getUpdateDate = (data) => {
    const upDate = data.UPD_DT;
    const extractDate = data.EXTRACT_DATE;
    return upDate || extractDate || "-";
};
const getCorrAddress = (data) => {
    let address = data.ADDR_CORR1;
    if (data.ADDR_CORR2) {
        address += "\n" + data.ADDR_CORR2;
    }
    if (data.ADDR_CORR3) {
        address += "\n" + data.ADDR_CORR3;
    }
    if (data.ADDR_CORR4) {
        address += "\n" + data.ADDR_CORR4;
    }
    if (data.ADDR_CORR5) {
        address += "\n" + data.ADDR_CORR5;
    }
    address +=
        "\n" +
        data.POSTCODE_CORR +
        "\n" +
        data.CITYDESC_CORR +
        "\n" +
        data.STATEDESC_CORR;
    return address;
};

const searchCorrAddress = (data) => {
    const no = data.ADDR_CORR1;
    const jalan = data.ADDR_CORR2;
    const taman = data.ADDR_CORR3;
    const postcode = data.POSTCODE;
    main.updateSearch({
        no,
        jalan,
        taman,
        postcode,
        type: "address",
        sensitive: true,
    });
    emitter.emit("search", true);
};
</script>
<style scoped></style>
