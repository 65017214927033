import AdminDashboard from "@/views/Backend/AdminDashboard.vue";
import Users from "@/views/Backend/Users.vue";
import Addusers from "@/views/Backend/Addusers.vue";
import Editusers from "@/views/Backend/Editusers.vue";
import Package from "@/views/Backend/Package.vue";
import Addpackage from "@/views/Backend/Addpackage.vue";
import Editpackage from "@/views/Backend/Editpackage.vue";
import Report from "@/views/Backend/Report.vue";
import Viewreport from "@/views/Backend/Viewreport.vue";
import Order from "@/views/Backend/Order.vue";
import Orderdetails from "@/views/Backend/Orderdetails.vue";
import Addorder from "@/views/Backend/Addorder.vue";
import Editorder from "@/views/Backend/Editorder.vue";

export default [
    {
        path: "/admin",
        name: "admin",
        meta: {
            admin: true,
        },
        children: [
            {
                path: "dashboard",
                name: "admin_dashboard",
                component: AdminDashboard,
            },
            {
                path: "user",
                name: "admin_user",
                component: Users,
            },
            {
                path: "user/add",
                name: "admin_adduser",
                component: Addusers,
            },
            {
                path: "user/:id/edit",
                name: "admin_edituser",
                component: Editusers,
                props: true,
            },
            {
                path: "package",
                name: "admin_package",
                component: Package,
            },
            {
                path: "package/create",
                name: "admin_addpackage",
                component: Addpackage,
            },
            {
                path: "package/:id/edit",
                name: "admin_editpackage",
                component: Editpackage,
                props: true,
            },
            {
                path: "order",
                name: "admin_order",
                component: Order,
            },
            {
                path: "order/add",
                name: "admin_addorder",
                component: Addorder,
            },
            {
                path: "order/:id/edit",
                name: "admin_editorder",
                component: Editorder,
                props: true,
            },
            {
                path: "order/details",
                name: "admin_orderdetails",
                component: Orderdetails,
            },
            {
                path: "report",
                name: "admin_report",
                component: Report,
            },
            {
                path: "report/view",
                name: "admin_viewreport",
                component: Viewreport,
            },
        ],
    },
    {
        path: "/:catchAll(.*)*",
        name: "PageNotFound",
        redirect: { name: "home" },
    },
];
