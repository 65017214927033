import { createApp, markRaw } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "./style.css";
import router from "./router";
import mixins from "./mixins";
import { createPinia } from "pinia";
import Default from "@/layouts/Default";
import Backend from "@/layouts/Backend";
import { VueReCaptcha } from "vue-recaptcha-v3";
import Vue3Html2pdf from "vue3-html2pdf";
import Pagination from "@/components/Pagination.vue";
import XDate from "@vuepic/vue-datepicker";
import Emitter from "tiny-emitter";
/* Detail UI */
import JPN from "@/components/Detail/JPN.vue";
import Detail from "@/components/Detail/Detail.vue";
const app = createApp(App);
const emitter = new Emitter();
const pinia = createPinia();
pinia.use(({ store }) => {
    store.$router = markRaw(router);
});
app.use(pinia);
app.provide("mixins", mixins);
app.use(router);
app.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPCTA_KEY,
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true,
    },
});
app.provide("emitter", emitter);
app.component("x-date", XDate);
app.component("vue3-html2pdf", Vue3Html2pdf);
app.component("default_layout", Default);
app.component("backend_layout", Backend);
app.component("pagination", Pagination);
/* Detail UI */
app.component("MYidentiy", JPN);
app.component("Detail", Detail);
app.mount("#app");
