<template>
    <div class="container mx-auto px-5">
        <div class="relative overflow-hidden bg-white rounded-md">
            <div class="relative pt-6 pb-16 sm:pb-24">
                <main class="mx-auto mt-16 max-w-7xl px-4 sm:mt-24">
                    <div class="text-center">
                        <h1
                            class="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl"
                        >
                            <span class="block xl:inline mr-0 md:mr-4">
                                Look For Anyone In
                            </span>
                            <span
                                class="block xl:inline bg-[url('@/assets/malaysia-flag.jpg')] bg-clip-text text-transparent bg-contain md:bg-cover drop-shadow-lg"
                            >
                                MALAYSIA
                            </span>
                        </h1>
                        <p
                            class="text-center mt-3 text-sm text-gray-500 sm:text-lg md:mt-2 md:w-full md:text-xl"
                        >
                            xSearch.Pro is the gateway to search for anyone in
                            Malaysia without their consent.
                        </p>
                        <p
                            class="text-center mt-3 text-sm text-gray-500 sm:text-lg md:mt-2 md:w-full md:text-xl"
                        >
                            Search with confidence as your identity are
                            completely anonymous.
                        </p>
                    </div>
                    <div>
                        <div
                            class="w-full flex flex-row text-white text-center mt-3"
                        >
                            <button
                                @click="main.changeForm('year')"
                                class="w-full rounded-l py-2"
                                :class="{
                                    'border-l-gray-400 border-t-gray-400 border-b-gray-400 bg-gray-400 hover:bg-indigo-500 hover:border-l-indigo-500 hover:border-t-indigo-500 hover:border-b-indigo-500':
                                        [
                                            'year',
                                            'name',
                                            'state',
                                            'year_state',
                                        ].indexOf(form.type) === -1,
                                    'bg-indigo-500 border-l-indigo-500 border-t-indigo-500 border-b-indigo-500':
                                        [
                                            'year',
                                            'name',
                                            'state',
                                            'year_state',
                                        ].indexOf(form.type) >= 0,
                                }"
                            >
                                Name
                            </button>
                            <button
                                @click="main.changeForm('phone')"
                                class="w-full py-2"
                                :class="{
                                    'border-l-gray-400 border-t-gray-400 border-b-gray-400 bg-gray-400 hover:bg-indigo-500 hover:border-l-indigo-500 hover:border-t-indigo-500 hover:border-b-indigo-500':
                                        form.type !== 'phone',
                                    'bg-indigo-500 border-l-indigo-500 border-t-indigo-500 border-b-indigo-500':
                                        main.isSearchType('phone'),
                                }"
                            >
                                Phone
                            </button>
                            <button
                                @click="main.changeForm('address')"
                                class="w-full py-2"
                                :class="{
                                    'border-l-gray-400 border-t-gray-400 border-b-gray-400 bg-gray-400 hover:bg-indigo-500 hover:border-l-indigo-500 hover:border-t-indigo-500 hover:border-b-indigo-500':
                                        form.type !== 'address',
                                    'bg-indigo-500 border-l-indigo-500 border-t-indigo-500 border-b-indigo-500':
                                        main.isSearchType('address'),
                                }"
                            >
                                Address
                            </button>
                            <button
                                @click="main.changeForm('email')"
                                class="w-full py-2"
                                :class="{
                                    'border-l-gray-400 border-t-gray-400 border-b-gray-400 bg-gray-400 hover:bg-indigo-500 hover:border-l-indigo-500 hover:border-t-indigo-500 hover:border-b-indigo-500':
                                        form.type !== 'email',
                                    'bg-indigo-500 border-l-indigo-500 border-t-indigo-500 border-b-indigo-500':
                                        main.isSearchType('email'),
                                }"
                            >
                                Email
                            </button>
                            <button
                                @click="main.changeForm('nric')"
                                class="w-full rounded-r py-2"
                                :class="{
                                    'border-l-gray-400 border-t-gray-400 border-b-gray-400 bg-gray-400 hover:bg-indigo-500 hover:border-l-indigo-500 hover:border-t-indigo-500 hover:border-b-indigo-500':
                                        form.type !== 'nric',
                                    'bg-indigo-500 border-l-indigo-500 border-t-indigo-500 border-b-indigo-500':
                                        main.isSearchType('nric'),
                                }"
                            >
                                MyKad
                            </button>
                        </div>
                        <div
                            v-if="
                                main.isSearchType('year') ||
                                main.isSearchType('state')
                            "
                        >
                            <div>
                                <div
                                    class="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0 my-5"
                                >
                                    <div class="flex items-center">
                                        <input
                                            id="year-radio"
                                            name="notification-method"
                                            type="radio"
                                            @change="
                                                main.changeSearchType('year')
                                            "
                                            :checked="main.isSearchType('year')"
                                            class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                        <label
                                            for="year-radio"
                                            class="ml-3 block font-medium leading-6 text-gray-900"
                                        >
                                            Birth Year
                                        </label>
                                    </div>
                                    <div class="flex items-center">
                                        <input
                                            id="state-radio"
                                            name="notification-method"
                                            type="radio"
                                            @change="
                                                main.changeSearchType('state')
                                            "
                                            :checked="
                                                main.isSearchType('state')
                                            "
                                            class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                        <label
                                            for="state-radio"
                                            class="ml-3 block font-medium leading-6 text-gray-900"
                                        >
                                            State
                                        </label>
                                    </div>
                                    <div class="flex items-center">
                                        <input
                                            id="year-state-radio"
                                            name="notification-method"
                                            type="radio"
                                            @change="
                                                main.changeSearchType(
                                                    'year_state'
                                                )
                                            "
                                            :checked="
                                                main.isSearchType('year_state')
                                            "
                                            class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                        <label
                                            for="year-state-radio"
                                            class="ml-3 block font-medium leading-6 text-gray-900"
                                        >
                                            Birth Year & State
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="main.isSearchType('year')"
                                class="mt-5 border relative rounded-md shadow-sm px-3 py-2"
                            >
                                <label
                                    class="block text-xs font-medium text-gray-400"
                                    for="email"
                                >
                                    Birth Year
                                </label>
                                <input
                                    id="email"
                                    v-model="form.year"
                                    class="block w-full sm:text-sm outline-none"
                                    name="year"
                                    type="number"
                                    placeholder="Not Sure"
                                    @keyup.enter="search"
                                />
                                <span
                                    class="block text-xs mt-3 text-gray-400"
                                    :class="{
                                        'text-red-400': validate.year,
                                    }"
                                >
                                    Insert valid Birth Year (Exp: 1999)
                                </span>
                            </div>
                            <div
                                v-if="main.isSearchType('state')"
                                class="mt-5 border relative rounded-md shadow-sm px-3 py-2"
                            >
                                <label
                                    class="block text-xs font-medium text-gray-400"
                                    for="state"
                                >
                                    State
                                </label>
                                <select
                                    v-model="form.state"
                                    id="state"
                                    class="block w-full sm:text-sm outline-none"
                                >
                                    <option value="" selected>NOT SURE</option>
                                    <option value="SELANGOR">SELANGOR</option>
                                    <option value="PERAK">PERAK</option>
                                    <option value="SARAWAK">SARAWAK</option>
                                    <option value="SABAH">SABAH</option>
                                    <option value="KEDAH">KEDAH</option>
                                    <option value="NEGERI SEMBILAN">
                                        NEGERI SEMBILAN
                                    </option>
                                    <option value="JOHOR">JOHOR</option>
                                    <option value="MELAKA">MELAKA</option>
                                    <option value="TERENGGANU">
                                        TERENGGANU
                                    </option>
                                    <option value="KELANTAN">KELANTAN</option>
                                    <option value="PULAU PINANG">
                                        PULAU PINANG
                                    </option>
                                    <option value="PAHANG">PAHANG</option>
                                    <option value="PERLIS">PERLIS</option>
                                    <option value="KUALA LUMPUR">
                                        W.P KUALA LUMPUR
                                    </option>
                                    <option value="PUTRAJAYA">
                                        W.P PUTRAJAYA
                                    </option>
                                    <option value="LABUAN">LABUAN</option>
                                </select>
                            </div>
                        </div>
                        <div
                            v-if="main.isSearchType('name')"
                            class="mt-5 border relative rounded-md shadow-sm px-3 py-2"
                        >
                            <label
                                class="block text-xs font-medium text-gray-400"
                                for="ic"
                            >
                                MyKad Number
                            </label>
                            <input
                                id="ic"
                                v-model="form.nric"
                                class="block w-full sm:text-sm outline-none"
                                name="ic"
                                type="text"
                                maxlength="30"
                                @keydown="checkKeyDownAlphaNumeric"
                                @keyup.enter="search"
                            />
                            <span
                                class="block text-xs mt-3 text-gray-400"
                                :class="{ 'text-red-400': validate.nric }"
                            >
                                Insert MyKad number (without "-" or "space")
                            </span>
                        </div>
                        <div
                            v-if="main.isSearchType('address')"
                            class="mt-5 border relative rounded-md shadow-sm px-3 py-2"
                        >
                            <label
                                class="block text-xs font-medium text-gray-400"
                                for="jalan"
                            >
                                Jalan / Lorong
                            </label>
                            <input
                                id="ic"
                                v-model="form.jalan"
                                class="block w-full sm:text-sm outline-none"
                                name="jalan"
                                placeholder="Not Sure"
                                type="text"
                                maxlength="30"
                                @keyup.enter="search"
                            />
                            <span
                                class="block text-xs mt-3 text-gray-400"
                                :class="{ 'text-red-400': validate.jalan }"
                            >
                                Insert Address Jalan (Exp: Jalan XSearch)
                            </span>
                        </div>
                        <div
                            v-if="main.isSearchType('address')"
                            class="mt-5 border relative rounded-md shadow-sm px-3 py-2"
                        >
                            <label
                                class="block text-xs font-medium text-gray-400"
                                for="ic"
                            >
                                Taman / Kampung
                            </label>
                            <input
                                id="ic"
                                v-model="form.taman"
                                class="block w-full sm:text-sm outline-none"
                                name="taman"
                                placeholder="Not Sure"
                                type="text"
                                maxlength="30"
                                @keyup.enter="search"
                            />
                            <span
                                class="block text-xs mt-3 text-gray-400"
                                :class="{ 'text-red-400': validate.taman }"
                            >
                                Insert Address Taman (Exp: Taman XSearch)
                            </span>
                        </div>
                        <div
                            v-if="main.isSearchType('address')"
                            class="mt-5 border relative rounded-md shadow-sm px-3 py-2"
                        >
                            <label
                                class="block text-xs font-medium text-gray-400"
                                for="ic"
                            >
                                Postcode
                            </label>
                            <input
                                id="ic"
                                v-model="form.postcode"
                                class="block w-full sm:text-sm outline-none"
                                name="postcode"
                                placeholder="Not Sure"
                                type="text"
                                maxlength="30"
                                @keyup.enter="search"
                            />
                            <span
                                class="block text-xs mt-3 text-gray-400"
                                :class="{
                                    'text-red-400': validate.postcode,
                                }"
                            >
                                Insert Address Postcode (Exp: 01010)
                            </span>
                        </div>
                        <div
                            v-if="main.isSearchType('nric')"
                            class="mt-5 border relative rounded-md shadow-sm px-3 py-2"
                        >
                            <label
                                class="block text-xs font-medium text-gray-400"
                                for="ic"
                            >
                                MyKad Number
                            </label>
                            <input
                                id="ic"
                                v-model="form.nric"
                                class="block w-full sm:text-sm outline-none"
                                name="ic"
                                type="text"
                                maxlength="30"
                                @keydown="checkKeyDownAlphaNumeric"
                                @keyup.enter="search"
                            />
                            <span
                                class="block text-xs mt-3 text-gray-400"
                                :class="{ 'text-red-400': validate.nric }"
                            >
                                Insert MyKad number (without "-" or "space")
                            </span>
                        </div>
                        <div
                            v-if="main.isSearchType('phone')"
                            class="mt-5 border relative rounded-md shadow-sm px-3 py-2"
                        >
                            <label
                                class="block text-xs font-medium text-gray-400"
                                for="phone"
                            >
                                Phone
                            </label>
                            <div class="relative mt-2 rounded-md shadow-sm">
                                <div
                                    class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                                >
                                    <span class="text-gray-500 sm:text-sm">
                                        +6
                                    </span>
                                </div>
                                <input
                                    id="phone"
                                    v-model="form.phone"
                                    class="block w-full outline-none rounded-md placeholder:text-gray-400 pl-10"
                                    name="phone"
                                    type="text"
                                    maxlength="30"
                                    @keydown="checkKeyDownNumeric"
                                    @keyup.enter="search"
                                />
                            </div>
                            <span
                                class="block text-xs mt-3 text-gray-400"
                                :class="{ 'text-red-400': validate.phone }"
                            >
                                Insert Phone number (without "-" or "space" with
                                60 country code)
                            </span>
                        </div>
                        <div
                            v-if="main.isSearchType('email')"
                            class="mt-5 border relative rounded-md shadow-sm px-3 py-2"
                        >
                            <label
                                class="block text-xs font-medium text-gray-400"
                                for="email"
                            >
                                Email
                            </label>
                            <input
                                id="email"
                                v-model="form.email"
                                class="block w-full sm:text-sm outline-none"
                                name="email"
                                type="email"
                                @keyup.enter="search"
                            />
                            <span
                                class="block text-xs mt-3 text-gray-400"
                                :class="{ 'text-red-400': validate.email }"
                            >
                                Insert valid Email (Exp: abc@email.com)
                            </span>
                        </div>
                        <div
                            v-if="
                                ['year', 'year_state', 'state'].indexOf(
                                    form.type
                                ) >= 0
                            "
                            class="mt-5 border relative rounded-md shadow-sm px-3 py-2"
                        >
                            <label
                                class="block text-xs font-medium text-gray-400"
                                for="name"
                            >
                                Name
                            </label>
                            <input
                                id="name"
                                v-model="form.name"
                                class="block w-full sm:text-sm outline-none"
                                name="name"
                                type="text"
                                @keydown="checkKeyDownAlphaSymbol"
                                @keyup.enter="search"
                            />
                            <span
                                class="block text-xs mt-3 text-gray-400"
                                :class="{ 'text-red-400': validate.name }"
                            >
                                Insert only alphabet, number and /
                            </span>
                        </div>
                        <!-- search button -->
                        <div class="flex flex-wrap gap-5 items-center mt-2">
                            <button
                                @click.prevent="search"
                                class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                type="button"
                            >
                                SEARCH
                            </button>
                            <div
                                class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300"
                            >
                                <label
                                    for="quantity"
                                    class="block text-xs font-medium text-gray-900"
                                >
                                    Result Quantity
                                    <Tooltip
                                        message="Default:20, Min:20, Max:200"
                                    >
                                        <span class="text-gray-500">(?)</span>
                                    </Tooltip>
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    v-model="form.result"
                                    @change="checkResult"
                                    class="block w-full p-0 text-gray-900 outline-none placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                    placeholder="20"
                                />
                            </div>
                            <div class="flex items-center">
                                <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
                                <Switch
                                    type="button"
                                    v-model="form.wildcard"
                                    @update:modelValue="checkPackage"
                                    class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                    :class="{
                                        'bg-gray-200': !form.wildcard,
                                        'bg-indigo-600': form.wildcard,
                                    }"
                                    role="switch"
                                    aria-checked="false"
                                    aria-labelledby="wildcard-label"
                                >
                                    <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                                    <transition>
                                        <span
                                            aria-hidden="true"
                                            class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                            :class="{
                                                'translate-x-0': !form.wildcard,
                                                'translate-x-5': form.wildcard,
                                            }"
                                        ></span>
                                    </transition>
                                </Switch>
                                <span class="ml-3 text-sm">
                                    <label
                                        class="font-medium text-gray-900"
                                        :class="{
                                            'text-red-500':
                                                validate.wildcard === false,
                                        }"
                                    >
                                        Enable Advance Search
                                    </label>
                                    <Tooltip
                                        message="I will slow on rendering result. Note: Must login and Socket must be connected."
                                    >
                                        <span class="text-gray-500"> (?) </span>
                                    </Tooltip>
                                </span>
                            </div>
                            <div class="font-bold">
                                <span>Socket Status</span>:
                                <span
                                    :class="{
                                        'text-green-500':
                                            main.isSocketConnected,
                                        'text-red-500': !main.isSocketConnected,
                                    }"
                                >
                                    {{
                                        main.isSocketConnected
                                            ? "Online"
                                            : "Offline, Please refresh page. Note: Must login first."
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <hr />
            <div v-if="auth.getUser && auth.getUser.package" class="p-2">
                <span>Current Package : </span>
                {{ auth.getUser.package ? auth.getUser.package.name : "N/A" }}
                ( Expired At:&nbsp;
                <span
                    :class="{
                        'text-red-600': main.isExpiredDate(
                            auth.getUser.packages[0].pivot.expired_at
                        ),
                        'text-green-600': !main.isExpiredDate(
                            auth.getUser.packages[0].pivot.expired_at
                        ),
                    }"
                >
                    {{
                        auth.getUser.package.id < 3
                            ? "N/A"
                            : main.getDate(
                                  auth.getUser.packages[0].pivot.expired_at
                              )
                    }}
                </span>
                )
            </div>
        </div>
        <div class="mt-5 flex flex-wrap">
            <div class="w-full md:w-1/3">
                <div
                    class="flex flex-col px-4 mb-4 bg-white md:mr-5 shadow-md rounded-lg p-5"
                >
                    <img
                        src="img/idcard.png"
                        alt="MyKad"
                        class="w-32 mx-auto my-2"
                    />
                    <div>
                        <h3 class="text-lg font-semibold text-blue-400 py-2">
                            Get ID Number by Name
                        </h3>
                        <div class="text-sm">
                            Get all Person Detail by Search Name.
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full md:w-1/3">
                <div
                    class="flex flex-col px-4 mb-4 bg-white md:mr-5 shadow-md rounded-lg p-5"
                >
                    <img
                        src="img/user-data.png"
                        alt="MyKad"
                        class="w-32 mx-auto my-4"
                    />
                    <div>
                        <h3 class="text-lg font-semibold text-blue-400">
                            Search Person by ID
                        </h3>
                        <div class="text-sm">
                            Can get detail of the person as address, photo and
                            more.
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full md:w-1/3">
                <div
                    class="flex flex-col px-4 mb-4 bg-white shadow-md rounded-lg p-5"
                >
                    <img
                        src="img/phone-detail.png"
                        alt="Mobile Phone Detail"
                        class="w-32 mx-auto my-4"
                    />
                    <div>
                        <h3 class="text-lg font-semibold text-blue-400">
                            Search Mobile Detail
                        </h3>
                        <div class="text-sm">
                            Include Service Provider, name with phone number.
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full md:w-1/3">
                <div
                    class="flex flex-col px-4 mb-4 bg-white md:mr-5 shadow-md rounded-lg p-5"
                >
                    <img
                        src="img/online-casino.png"
                        alt="Online Casino"
                        class="w-32 mx-auto my-4"
                    />
                    <div>
                        <h3 class="text-lg font-semibold text-blue-400">
                            Search Online Casino
                        </h3>
                        <div class="text-sm">Some data has account detail.</div>
                    </div>
                </div>
            </div>
            <div class="w-full md:w-1/3">
                <div
                    class="flex flex-col px-4 mb-4 bg-white md:mr-5 shadow-md rounded-lg p-5"
                >
                    <img
                        src="img/account-detail.png"
                        alt="Database Leaked"
                        class="w-32 mx-auto my-4"
                    />
                    <div>
                        <h3 class="text-lg font-semibold text-blue-400">
                            Password
                        </h3>
                        <div class="text-sm">
                            Some data including username and password
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full md:w-1/3">
                <div
                    class="flex flex-col px-4 mb-4 bg-white shadow-md rounded-lg p-5"
                >
                    <img
                        src="img/facebook.png"
                        alt="Facebook"
                        class="w-32 mx-auto my-4"
                    />
                    <div>
                        <h3 class="text-lg font-semibold text-blue-400">
                            Facebook Account
                        </h3>
                        <div class="text-sm">
                            Facebook account contain link and profile detail.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white shadow-md rounded-lg p-5 mt-5">
            <div class="container mx-auto">
                <div class="flex flex-wrap -mx-4">
                    <div class="lg:w-1/3 px-4">
                        <div class="flex mb-4">
                            <div class="mr-3">
                                <img src="img/secure.png" class="w-32" />
                            </div>
                            <div>
                                <h3 class="text-lg font-semibold">Secure</h3>
                                <div class="text-sm">
                                    You Will Never Be Found Or Traced. All The
                                    Searches Are Private And Encrypted.
                                    <div class="font-semibold">
                                        Safety Is The Number One Priority.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lg:w-1/3 px-4">
                        <div class="flex mb-4">
                            <div class="mr-3">
                                <img src="img/search.png" class="w-32" />
                            </div>
                            <div>
                                <h3 class="text-lg font-semibold">Data</h3>
                                <div class="text-sm">
                                    Almost 99% Of Malaysian Personal Data Can Be
                                    Found In This Website.
                                    <div class="font-semibold">
                                        Use It Wisely.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lg:w-1/3 px-4">
                        <div class="flex mb-4">
                            <div class="mr-3">
                                <img src="img/efficient.png" class="w-32" />
                            </div>
                            <div>
                                <h3 class="text-lg font-semibold">Efficient</h3>
                                <div class="text-sm">
                                    Just Enter Keywords In Search Field And The
                                    Result Will Be Presented To You.
                                    <div class="font-semibold">
                                        Time Is Money.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="bg-white flex flex-wrap gap-5 md:gap-0 md:justify-around shadow-md rounded-lg p-5 mt-5"
        >
            <div class="flex flex-row">
                <div class="mb-5">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        fill="currentColor"
                        class="w-16 mx-auto"
                    >
                        <path
                            d="M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"
                        />
                    </svg>
                </div>
                <div class="text-left ml-5">
                    <div class="font-bold">
                        <router-link
                            :to="{ name: 'pdpa' }"
                            target="_blank"
                            class="text-blue-500 hover:text-blue-600"
                        >
                            Privacy Policy
                        </router-link>
                        &
                        <router-link
                            :to="{ name: 'tnc' }"
                            target="_blank"
                            class="text-blue-500 hover:text-blue-600"
                        >
                            Term & Condition
                        </router-link>
                    </div>
                    <div>
                        <div>
                            By using our website, you hereby consent to our
                        </div>
                        <div>Privacy Policy and agree to its terms.</div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row">
                <div class="mb-5">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                        fill="currentColor"
                        class="w-16 h-16 mx-auto"
                    >
                        <path
                            d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7H514.3c3.9 0 7.6-.7 11-2.1l-261-205.6z"
                        />
                    </svg>
                </div>
                <div class="text-left ml-5">
                    <div class="font-bold">Unlisted Data</div>
                    <p>
                        Please contact our
                        <router-link
                            :to="{ name: 'telegram' }"
                            target="_blank"
                            class="text-blue-500 hover:text-blue-600"
                        >
                            Telegram
                        </router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { inject, reactive } from "vue";
import { useAuthStore, useMainStore } from "@/store/index.js";
import Tooltip from "@/components/Tooltip.vue";
import { Switch } from "@headlessui/vue";

const validate = reactive({
    name: null,
    year: null,
    state: null,
    jalan: null,
    taman: null,
    postcode: null,
    wildcard: null,
    nric: null,
    phone: null,
    email: null,
    result: 20,
});
const main = useMainStore();
const auth = useAuthStore();
const emitter = inject("emitter");

let form = reactive(main.getSearch);

const checkKeyDownAlphaSymbol = (event) => {
    if (
        !/[a-zA-Z\- /]|ArrowRight|ArrowLeft/.test(event.key) &&
        event.key !== "Backspace"
    ) {
        event.preventDefault();
    }
};
const checkKeyDownAlphaNumeric = (event) => {
    if (
        !/[a-zA-Z0-9]|ArrowRight|ArrowLeft/.test(event.key) &&
        event.key !== "Backspace"
    ) {
        event.preventDefault();
    }
};
const checkKeyDownNumeric = (event) => {
    if (
        !/[0-9]|ArrowRight|ArrowLeft/.test(event.key) &&
        event.key !== "Backspace"
    ) {
        event.preventDefault();
    }
};

const checkPackage = () => {
    if (!auth.getUser) return false;
    if (auth.getUser && !auth.getUser.is_admin) {
        const currentPackage = auth.getUser.package
            ? auth.getUser.package.name.toLowerCase()
            : null;
        const isExpired = auth.getUser.is_expired;
        if (currentPackage !== "diamond" || isExpired) {
            form.wildcard = false;
            main.addMessage({
                success: false,
                message: "This feature only for Diamond Package User.",
            });
            return false;
        }
    }
    return true;
};

const checkResult = () => {
    if (form.result > 200) {
        form.result = 200;
    }
};

const search = async () => {
    if (form.result < 20) {
        form.result = 20;
    }
    if (form.result > 200) {
        form.result = 200;
    }
    let valid;
    switch (form.type) {
        case "phone":
            valid = !!form.phone;
            validate.phone = form.wildcard && !valid;
            break;
        case "email":
            valid =
                !!form.email &&
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(form.email);
            validate.email = form.wildcard && !valid;
            break;
        case "nric":
            valid = !!form.nric;
            validate.nric = form.wildcard && !valid;
            break;
        case "address":
            validate.wildcard = form.wildcard;
            valid =
                ((!!form.jalan && !!form.postcode) ||
                    (!!form.taman && !!form.postcode)) &&
                validate.wildcard;
            validate.jalan = !valid;
            validate.taman = !valid;
            validate.postcode = !valid;
            break;
        case "state":
        case "year":
        case "year_state":
            valid = !!form.name && form.name.length > 1;
            validate.name = !valid;
            break;
        default:
            valid = false;
    }

    if (!valid) {
        if (form.wildcard) {
            main.addMessage({
                message:
                    "Only multiple conditions are allowed for Advance Search",
                success: false,
            });
        } else {
            main.addMessage({
                message: "Please fill the form correctly",
                success: false,
            });
        }
        return;
    }
    delete form.no;
    main.setSearchForm(form);
    emitter.emit("search");
};
</script>
