<template>
    <div
        class="container mx-auto px-4 pb-12 w-full leading-6 text-left text-neutral-800"
    >
        <div class="border rounded p-5 bg-white">
            <div class="md:flex justify-between">
                <div class="flex md:flex-col justify-center md:justify-start">
                    <div class="text-md font-bold">Details For type :</div>
                    <div class="ml-3 md:ml-0 text-md font-bold">
                        Type :
                        {{ getType(props.search.type) }}
                    </div>
                    <div
                        v-html="searchWord()"
                        class="ml-3 md:ml-0 text-md font-semi-bold"
                    ></div>
                </div>
                <div></div>
                <div
                    class="flex mt-3 flex-1 md:mt-0 gap-2 items-center justify-center md:justify-end"
                >
                    <router-link :to="{ name: 'search' }" class="text-center">
                        <div
                            class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Back To Search
                        </div>
                    </router-link>
                    <a
                        @click.prevent="purchase"
                        href="#"
                        class="items-center text-white font-bold py-2 px-4 rounded"
                        :class="{
                            'bg-indigo-600 hover:bg-indigo-800':
                                !main.isPurchased,
                            'bg-green-600 hover:bg-green-800': main.isPurchased,
                        }"
                    >
                        <div v-if="main.isLoading" class="text-white">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                class="w-6 h-6 animate-spin mx-auto"
                                fill="currentColor"
                            >
                                <path
                                    class="fa-primary"
                                    d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM96 256A48 48 0 1 0 0 256a48 48 0 1 0 96 0zM75 142.9A48 48 0 1 0 142.9 75 48 48 0 1 0 75 142.9z"
                                />
                                <path
                                    class="fa-secondary"
                                    d="M369.1 75A48 48 0 1 1 437 142.9 48 48 0 1 1 369.1 75zM416 256a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM208 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM75 369.1A48 48 0 1 1 142.9 437 48 48 0 1 1 75 369.1zm294.2 0A48 48 0 1 1 437 437a48 48 0 1 1 -67.9-67.9z"
                                />
                            </svg>
                        </div>
                        <div v-else>
                            <span v-if="!main.isPurchased">
                                {{
                                    !main.isLoading
                                        ? "Purchase"
                                        : "Please wait..."
                                }}
                            </span>
                            <span v-else> Purchased </span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div>
            <div
                v-for="(result, key) in main.getResultsFilter(
                    props.request_code
                )"
                :key="key"
                class="flex relative flex-col mt-4 min-w-0 text-left break-words bg-clip-border bg-white rounded border border-solid border-neutral-200"
            >
                <div
                    v-if="result.data && result.data.length > 0"
                    class="py-3 px-5 mb-0 break-words rounded border-b border-solid border-neutral-200 bg-neutral-100 bg-opacity-[0.03]"
                >
                    <strong class="text-indigo-600 font-bold">
                        {{ result.name }}'s Details (Total
                        {{ result.data ? result.data.length : 1 }} Result)
                    </strong>
                </div>
                <div v-if="result.data && result.data.length > 0">
                    <div v-for="(result2, key) in result.data" :key="key">
                        <component
                            :is="helpers.getDetailTemplate(result.type)"
                            :id="key"
                            :total="result2.data ? result2.data.length : 1"
                            :data="result2"
                        />
                    </div>
                </div>
            </div>
            <div v-if="main.isLoading" class="w-full py-2 text-lg text-center">
                Loading
            </div>
            <div v-else class="w-full py-2 text-lg text-center">Complete</div>
        </div>
    </div>
</template>
<script setup>
import { useMainStore } from "@/store/index.js";
import { inject, onBeforeUnmount } from "vue";
import { useRoute } from "vue-router";

const { helpers } = inject("mixins");
const main = useMainStore();
const route = useRoute();
const emitter = inject("emitter");
// eslint-disable-next-line no-undef
const { request_code } = route.params;
const props = main.getSearchSession(request_code);
main.setSession(props.request_code);
const searchWord = () => {
    const words = [];
    switch (props.search.type) {
        case "email":
            words.push("Email : " + props.search.email);
            break;
        case "nric":
            words.push("NRIC : " + props.search.nric);
            break;
        case "phone":
            words.push("Phone : " + props.search.phone);
            break;
        case "state":
            words.push(
                "State : " +
                    (props.search.state && props.search.state.length
                        ? props.search.state
                        : "Not Sure")
            );
            words.push("Name : " + props.search.name);
            break;
        case "year":
            words.push("Birth Year : " + (props.search.year ?? "Not Sure"));
            words.push("Name : " + props.search.name);
            break;
        case "address":
            if (props.search.no && props.search.no.length > 0) {
                words.push("No : " + props.search.no);
            }
            words.push(
                "Jalan : " +
                    (props.search.jalan && props.search.jalan.length > 0
                        ? props.search.jalan
                        : "Not Sure")
            );
            words.push(
                "Taman : " +
                    (props.search.taman && props.search.taman.length > 0
                        ? props.search.taman
                        : "Not Sure")
            );
            words.push("Postcode : " + (props.search.postcode ?? "Not Sure"));
            break;
        default:
            break;
    }
    return words.join("</br>");
};
const purchase = () => {
    if (main.isPurchased) return;
    const purchaseData = main
        .getResultsFilter(props.request_code)
        .map(({ type, data }) => {
            const getData = data
                .filter((a) => a.purchase === false)
                .map((a) => a.ID || a.id);
            return {
                type: type,
                data: getData,
            };
        });
    main.createOrder({
        type: props.search.type,
        purchase: purchaseData,
        value: searchWord(),
    }).then(async (res) => {
        if (res.success) {
            await emitter.emit("search");
            await main.addMessage({
                message: "Purchase Successfully, please wait for result.",
                success: true,
            });
        }
    });
};
const getType = (type) => {
    switch (type) {
        case "nric":
            return "MYKAD";
        case "year":
            return "BIRTH YEAR";
        default:
            return type.toUpperCase();
    }
};
onBeforeUnmount(() => {
    main.setSearch();
});
</script>
