import { createRouter, createWebHistory } from "vue-router";
import frontend from "@/router/frontend";
import backend from "@/router/backend";
import { useAuthStore, useMainStore } from "@/store";

const routes = [
    ...frontend,
    ...backend,
    {
        path: "/:catchAll(.*)*",
        name: "PageNotFound",
        redirect: { name: "home" },
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.VITE_BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    // console.log("to");
    // console.log(to);
    // console.log("from");
    // console.log(from);
    if (to.name === "telegram") {
        const main = useMainStore();
        await main.getTelegram();
        return;
    }
    const requireAuth = to.meta.auth;
    const { getToken, me, getUser } = useAuthStore();
    const { changeLayout, hasSearchSession, setSession } = useMainStore();

    if (getToken) {
        me();
    }
    switch (true) {
        case getToken && to.meta.admin:
            changeLayout("default_layout");
            break;
        default:
            changeLayout("default_layout");
            break;
    }

    if (requireAuth && !getToken) {
        next({ name: "login" });
        return;
    }
    if (to.meta.admin && !getUser.is_admin) {
        next({ name: "search" });
        return;
    }

    if (getToken && to.meta.guest) {
        next({ name: "dashboard" });
        return;
    }
    if (to.name !== "search_result") {
        setSession();
    }

    if (
        to.name === "search_result" &&
        !hasSearchSession(to.params.request_code)
    ) {
        next({ name: "search" });
        return;
    }
    next();
});

export default router;
