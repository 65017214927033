import Search from "@/views/Frontend/Search.vue";
import MyKadResult from "@/views/Frontend/Search/MyKadResult.vue";
import PersonalDetailResult from "@/views/Frontend/Search/PersonalDetailResult.vue";
import Payment from "@/views/Frontend/Payment.vue";
import Login from "@/views/Frontend/Login.vue";
import Register from "@/views/Frontend/Register.vue";
import Profile from "@/views/Frontend/Profile.vue";
import Dashboard from "@/views/Frontend/Dashboard.vue";
import PDPA from "@/views/Frontend/PDPA.vue";
import TNC from "@/views/Frontend/TNC.vue";
import Blacklist from "@/views/Frontend/Blacklist.vue";
import User from "@/views/Frontend/User.vue";
import Report from "@/views/Frontend/Report.vue";

export default [
    {
        path: "/",
        name: "home",
        component: Search,
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            guest: true,
        },
    },
    {
        path: "/register",
        name: "register",
        component: Register,
        meta: {
            guest: true,
        },
    },
    {
        path: "/pdpa",
        name: "pdpa",
        component: PDPA,
    },
    {
        path: "/tnc",
        name: "tnc",
        component: TNC,
    },
    {
        path: "/profile",
        name: "profile",
        component: Profile,
        meta: {
            auth: true,
        },
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
            auth: true,
        },
    },
    {
        path: "/user",
        name: "user",
        component: User,
        meta: {
            auth: true,
            admin: true,
        },
    },
    {
        path: "/report",
        name: "report",
        component: Report,
        meta: {
            auth: true,
            admin: true,
        },
    },
    {
        path: "/search",
        children: [
            {
                path: "",
                name: "search",
                component: Search,
                meta: {
                    auth: false,
                },
            },
            {
                path: ":request_code",
                name: "search_result",
                component: PersonalDetailResult,
                meta: {
                    auth: true,
                },
            },
        ],
    },
    {
        path: "/result",
        name: "result",
        meta: {
            auth: true,
        },
        children: [
            {
                path: "mykad",
                name: "mykad_result",
                component: MyKadResult,
            },
            {
                path: "detail",
                name: "detail_result",
                component: PersonalDetailResult,
            },
        ],
    },
    {
        path: "/payment",
        name: "payment",
        meta: {
            auth: true,
        },
        component: Payment,
    },
    {
        path: "/telegram",
        name: "telegram",
        meta: {
            auth: true,
        },
        component: null,
    },
    {
        path: "/blacklist",
        name: "blacklist",
        meta: {
            auth: true,
            admin: true,
        },
        component: Blacklist,
    },
    {
        path: "/:catchAll(.*)*",
        name: "PageNotFound",
        redirect: { name: "login" },
    },
];
